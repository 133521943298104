// invoice-types.ts
export interface InvoiceType {
    type: string;
    name: string;
    code: string;
    from: string;
    to: string;
}


export const invoice_types_all: InvoiceType[] = [
    {
        type: "PAYMENT_ORDER",
        name: "De Organización a un Proveedor",
        code: "SUSCRIPTOR-PROVIDER",
        from: "SUSCRIPTOR",
        to: "PROVIDER",
    },
    {
        type: "PAYMENT_ORDER",
        name: "De Organización a Otra Organización",
        code: "SUSCRIPTOR-SUSCRIPTOR",
        from: "SUSCRIPTOR",
        to: "SUSCRIPTOR"
    },
    {
        type: "PAYMENT_ORDER",
        name: "De Organización a  Empleado ",
        code: "SUSCRIPTOR-PAYROLL",
        from: "SUSCRIPTOR",
        to: "PAYROLL"
    },
    {
        type: "PAYMENT_ORDER",
        name: "De Organización a Un Accionista",
        code: "SUSCRIPTOR-SHAREHOLDER",
        from: "SUSCRIPTOR",
        to: "SHAREHOLDER"
    }
];

export const invoice_types: InvoiceType[] = [
    {
        type: "PAYMENT_ORDER",
        name: "Pago a Proveedores",
        code: "SUSCRIPTOR-PROVIDER",
        from: "SUSCRIPTOR",
        to: "PROVIDER"
    },
    {
        type: "PAYMENT_ORDER",
        name: "Traspaso Entre Cuentas",
        code: "SUSCRIPTOR-SUSCRIPTOR",
        from: "SUSCRIPTOR",
        to: "SUSCRIPTOR"
    },
    {
        type: "PAYMENT_ORDER",
        name: "Pago a Empleados",
        code: "SUSCRIPTOR-PAYROLL",
        from: "SUSCRIPTOR",
        to: "PAYROLL"
    }
];

export const invoice_types_code_obj= {
    "SUSCRIPTOR-PROVIDER":{
        type: "PAYMENT_ORDER",
        name: "De Organización a un Proveedor",
        code: "SUSCRIPTOR-PROVIDER",
        from: "SUSCRIPTOR",
        to: "PROVIDER"
    },
    "SUSCRIPTOR-SUSCRIPTOR":{
        type: "PAYMENT_ORDER",
        name: "De Organización a Otra Organización",
        code: "SUSCRIPTOR-SUSCRIPTOR",
        from: "SUSCRIPTOR",
        to: "SUSCRIPTOR"
    },
    "SUSCRIPTOR-PAYROLL":{
        type: "PAYMENT_ORDER",
        name: "De Organización a Empleado",
        code: "SUSCRIPTOR-PAYROLL",
        from: "SUSCRIPTOR",
        to: "PAYROLL"
    },
    "SUSCRIPTOR-SHAREHOLDER":{
        type: "PAYMENT_ORDER",
        name: "De Organización a Un Accionista",
        code: "SUSCRIPTOR-SHAREHOLDER",
        from: "SUSCRIPTOR",
        to: "SHAREHOLDER"
    }
};


export const invoice_types_code_description_obj= {
    "SUSCRIPTOR-PROVIDER":{
        name: "Pago a Proveedores",
        from: "Organización",
        to: "Proveedor"
    },
    "SUSCRIPTOR-SUSCRIPTOR":{
        name: "Traspaso Entre Cuentas",
        from: "Organización",
        to: "Organización"
    },
    "SUSCRIPTOR-PAYROLL":{
        name: "Pago a Empleados",
        from: "Organización",
        to: "Empleado"
    },
    "SUSCRIPTOR-SHAREHOLDER":{
        name: "De Organización a Un Accionista",
        from: "Organización",
        to: "Accionista"
    }
};