<div class=" col-12 col-md-4 offset-md-4 mt-4 ">
    <h5 class="mb-4">Registro
    </h5>

    <div class="mt-3 my-3 p-3 bg-body rounded shadow-sm">


        <div *ngIf="errors?.length > 0" class="mb-4 text-start p-3 box-errors">
            <ng-container *ngFor="let item of errors">

                <div class="d-inline-flex w-10">
                </div>
                <div class="d-inline-flex w-90">
                    <i class="fas fa-info-circle pe-2 "></i>

                    <span>{{item?.msg }}  </span>

                </div>
            </ng-container>
        </div>

        <form autocomplete="nope" novalidate [formGroup]="form" (ngSubmit)="submit()" class="text-start">
            <div class="row mt-3">

                <div class="form-group  col-12">

                    <div class="form-floating">
                        <select [ngClass]="{'error': form.controls.type.invalid && form.controls.type.dirty}" class="form-select" id="type" formControlName="type" aria-label="Floating label select example">
                      <option  selected value="PERSON">Ejecutivo</option>
    
                   
                    </select>
                        <label for="type">Tipo</label>
                    </div>
                </div>

                <div class="form-group col-12 mt-3">

                    <div class="form-floating ">
                        <input type="text" [ngClass]="{'error': form.controls.nombre.invalid && form.controls.nombre.dirty}" class="form-control text-capitalize" id="nombre" formControlName="nombre" placeholder="name@example.com">
                        <label for="nombre" class=" label font-weight-normal" style="word-wrap: break-word;">
                            <span >Nombre</span>
                          </label>
                    </div>

                </div>

                <div class="form-group col-12 mt-3" *ngIf="form.getRawValue().type == 'PERSON' ">

                    <div class="form-floating">
                        <input type="text" [ngClass]="{'error': form.controls.apellido.invalid && form.controls.apellido.dirty}" class="form-control text-capitalize" id="apellido" formControlName="apellido" placeholder="name@example.com">
                        <label for="apellido" class=" label font-weight-normal" style="word-wrap: break-word;">
                            <span >Apellido</span>
                          </label>
                    </div>

                </div>
                <!--                 
                <div class="form-group col-12  mt-3" *ngIf="form.getRawValue().type == 'PERSON' ">

                    <div class="text-secondary text-end fz-12">
                        Eje: +584240000000
                    </div>
                    <div class="form-floating ">
                        <input placeholder="V00000000" type="text" [ngClass]="{'error': form.controls.telefono.invalid && form.controls.telefono.dirty}" class="form-control" id="telefono" formControlName="telefono" placeholder="name@example.com">
                        <label for="telefono" class=" label font-weight-normal" style="word-wrap: break-word;">
                            <span >Teléfono</span>
                          </label>
                    </div>

                    <div class="form-control-feedback help-block" *ngIf="form.get('telefono').errors">
                        <div *ngIf="form.get('telefono').errors['required'] && !form.get('telefono').pristine">
                            Campo requerido
                        </div>
                    </div>

                </div> -->

                <div class="form-group col-12 mt-3">

                    <div class="form-floating ">
                        <input type="text" [ngClass]="{'error': form.controls.correo.invalid && form.controls.correo.dirty}" class="form-control" id="correo" formControlName="correo" placeholder="name@example.com">
                        <label for="correo" class=" label font-weight-normal" style="word-wrap: break-word;">
                            <span >Username/correo</span>
                          </label>
                    </div>


                    <div class="form-control-feedback help-block" *ngIf="form.get('correo').errors">
                        <div *ngIf="form.get('correo').errors['required'] && !form.get('correo').pristine">
                            Campo requerido
                        </div>
                    </div>
                </div>


                <div class="form-group col-12 mt-3">

                    <div class="form-floating ">
                        <input type="password" [ngClass]="{'error': form.controls.password.invalid && form.controls.password.dirty}" class="form-control" id="password" formControlName="password" placeholder="name@example.com">
                        <label for="password" class=" label font-weight-normal" style="word-wrap: break-word;">
                            <span >Password</span>
                          </label>
                    </div>

                    <div class="form-control-feedback help-block" *ngIf="form.get('password').errors">
                        <div *ngIf="form.get('password').errors['required'] && !form.get('password').pristine">
                            Campo requerido
                        </div>
                        <div *ngIf="form.get('password').hasError('minlength')  && !form.get('password').pristine">
                            Mínimo {{passwordMinLength}} caracteres
                        </div>

                        <div *ngIf="form.get('password').hasError('maxlength')  && !form.get('password').pristine">
                            Máximo {{passwordMaxLength}} caracteres
                        </div>

                    </div>
                </div>

                <br>
                <div class=" col-12 mt-2 mb-2">
                    <button type="submit" [disabled]="!form.valid || submit_disabled" class="btn btn-block btn-primary ">
                                                    <span >Continuar</span>
                                                    <span *ngIf="submit_disabled">
                                                      <i class="fa  fa-spinner fa-spin ms-2"></i>
                                                    </span>
                                                  </button>
                </div>

            </div>
        </form>
    </div>
</div>