import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, lastValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})

export class LaService {
  private url_la: any;
 env:any = environment;
 

  constructor(
    public httpClient: HttpClient,
  ) {
    this.url_la = this.env.BASE_LA;

  }

  getClient = async (identity: any) => {

    const headers = { 'content-type': 'application/json' }
    const request = {};
    const body = {identity};
    try {

      return lastValueFrom(this.httpClient.post<any>(this.baseUrl + '/api/la/cliente', body, { headers: headers, params: request }));

    } catch (error) {

      console.error(error);

    }

  }


  getCalendario = async (identity: any) => {

    const headers = { 'content-type': 'application/json' }
    const request = {};
    const body = {identity};
    try {

      return lastValueFrom(this.httpClient.post<any>(this.baseUrl + '/api/la/calendario', body, { headers: headers, params: request }));

    } catch (error) {

      console.error(error);

    }

  }


  getConsolidado = async (params:any) => {
    const { identity, date } = params;

    const headers = { 'content-type': 'application/json' }
    const request = {};
    const body = {
      identity,
      date

    };
    try {

      return  lastValueFrom( this.httpClient.post<any>(this.baseUrl + '/api/la/consolidado', body, { headers: headers, params: request }));

    } catch (error) {

      console.error(error);

    }

  }



  getTransacciones = async (params: any) => {

    const { identity, dateIn, dateEnd, mesa, Ctacustodia } = params;
    
    const headers = { 'content-type': 'application/json' }
    const request = {};
    const body = {
      identity,
      dateIn,
      dateEnd,
      mesa,
      Ctacustodia
    };
    try {

      return lastValueFrom(this.httpClient.post<any>(this.baseUrl + '/api/la/transacciones', body, { headers: headers, params: request }));

    } catch (error) {

      console.error(error);

    }

  }


  getPosicion = async (params:any) => {
    const { identity, date } = params;

    const headers = { 'content-type': 'application/json' }
    const request = {};
    const body = {
      identity,
      date

    };
    try {

      return  lastValueFrom( this.httpClient.post<any>(this.baseUrl + '/api/la/posicion', body, { headers: headers, params: request }));

    } catch (error) {

      console.error(error);

    }

  }

   getTitulos = async (params: any) => {
    
    const {type, Cotitulo, Mrkt, Vigencia, Moneda,allTitules } = params;

    const headers = { 'content-type': 'application/json' }
    const request = {};

    const body = {
      type,
      Cotitulo,
      Mrkt,
      Vigencia,
      Moneda,
      allTitules
    };

    try {

      return lastValueFrom(this.httpClient.post<any>(this.baseUrl + '/api/la/titulo', body, { headers: headers, params: request }));

    } catch (error) {

      console.error(error);

    }
  
  }


  setNuevaOrden = async (params: any) => {
    
    const headers = { 'content-type': 'application/json' }
    const request = {};

    const body = params;

    try {

      return lastValueFrom(this.httpClient.post<any>(this.baseUrl + '/api/la/nueva-orden', body, { headers: headers, params: request }));

    } catch (error) {

      console.error(error);

    }
    

  }


  setNuevaOrdenPost = async (params: any) => {
    
    const headers = { }
    const request = {};


    try {

      return lastValueFrom(this.httpClient.post<any>(this.baseUrl + '/api/post/insertar', params, { headers: headers, params: request }));

    } catch (error) {

      console.error(error);

    }
    

  }
  
  
  getTransaccionesActivas = async (params:any) => {
    const { identity } = params;

    const headers = { 'content-type': 'application/json' }
    const request = {identity};
   
    try {

      return  lastValueFrom( this.httpClient.get<any>(this.baseUrl + '/api/la/operaciones-activas', { headers: headers, params: request }));

    } catch (error) {

      console.error(error);

    }

  }




  sendNoteClient = async ({phone,client,msg}:any) => {
   const data =  {
    phone,
    client,
    msg
    
    }

    const headers = { 'content-type': 'application/json' }
    const request = {};

    const body = data;

    try {

      return lastValueFrom(this.httpClient.post<any>('https://otp.akeela.co/sendsms/general', body, { headers: headers, params: request }));

    } catch (error) {

      console.error(error);

    }
   
 }



  get baseUrl() {

    return this.url_la;
  }



  setUpload = async (params: any) => {
    
    const headers = {}
    const request = {};


    try {

      return lastValueFrom(this.httpClient.post<any>(this.baseUrl + '/api/post/upload', params, { headers: headers, params: request }));

    } catch (error) {

      console.error(error);

    }
    

  }
  


}
