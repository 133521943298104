<div
  class="flex flex-col md:flex-row md:justify-between md:items-center border-b"
>
  <h1 class="text-xl font-semibold">Orden de Pago</h1>
  <div class="flex items-center space-x-4">
    <!-- <div class="flex items-center space-x-2">
    <span class="text-gray-500">Production</span>
    <input type="checkbox" class="toggle-checkbox" checked />
  </div>
  <button class="text-gray-500 hover:text-gray-700">Help</button>
  <button class="text-gray-500 hover:text-gray-700">Profile</button>
  <button class="text-gray-500 hover:text-gray-700">🔔</button> -->
  </div>
</div>
