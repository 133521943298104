<div class="container">
    <div class="mt-md-3 p-3">
        <div class="row ">
            <div class=" col-12 p-0 ">
                <h5 class="mb-1 text-secondary  mb-4 text-center" > Nuevo Documento
                </h5>
                   <!-- <div class="col-12 col-md-6 offset-md-3  mt-4" *ngIf="_auth.IsPermitid(['OWNER','SHOP'])" >
                        <div class="card" [routerLink]="['/console/credit-save']">
                            <div class="card-body">
                                <h5 class="card-title">Credito
                                    <i class="float-end pt-2  fas fa-file-export"></i>
                                </h5>
                            </div>
                        </div>
                </div> -->
                <div class="col-12 col-md-6 offset-md-3  mt-4"  *ngIf="_auth.IsPermitid(['OWNER','PAYMENT_REQUEST','PAYMENT_AUTHORIZATION'])"  >
                    <div class="card" [routerLink]="['/console/order/new']">
                        <div class="card-body">
                            <h5 class="card-title">Orden de Pago
                                <i class="float-end pt-2  fas fa-file-export"></i>
                            </h5>
                        </div>
                 </div>
            </div>

            </div>

        </div>
     </div>
</div>
