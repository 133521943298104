import { DatePipe } from '@angular/common';
import { noUndefined } from '@angular/compiler/src/util';
import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { async } from '@angular/core/testing';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/services/auth/auth.service';
import { RedirecFormService } from 'src/app/services/redirec-form.service';
import { UserService } from 'src/app/services/user/user.service';
import { environment } from 'src/environments/environment';
import { isNullOrUndefined } from 'util';
import { ApproveConditionsComponent } from '../approve-conditions/approve-conditions.component';
import * as alertifyjs from 'alertifyjs';
import { log } from 'console';
import Swal from'sweetalert2';
import { person_types,person_types_code_obj,person_types_code_description_obj,person_types_plural_obj } from 'src/intefaces/person-type';

@Component({
  selector: 'app-contact-profile',
  templateUrl: './contact-profile.component.html',
  styleUrls: ['./contact-profile.component.css'],
  providers: [DatePipe]

})
export class ContactProfileComponent implements OnInit {
  usQuote: any = false;
  code_person:any;
  edit_quote:any;
  new_quote:any;
  submit_disabled_quote:any;
  person_types_code_description_obj:any=person_types_code_description_obj;
  supUse:any;
  queryParams: any;
  person: any;
  isClient: any;

  person_types_plural_obj :any = person_types_plural_obj;

  section_transactions: any = [];
  transaction: any = {};
  section_transaction: any;
  loading = false;
  buro_response: any;
  buro: any;
  buro_score: any;
  activatePsico: any;
  showActivatePsico: any;

  loading_buro = false;
  psicometrico: any;
  psicometrico_response: any;
  psicometrico_array: any = [];
  status_approved: any;
   type_approved: any;
   clientQuota:any;
   clientAprov:any;
   clientAprovBuro:any;
   searchPerson:any;
   countInvoice:any;

   identityResponse:any;
   result:any;
   errorMsg:any;
   loadQuota = false;
   env = environment;
   base_amount:any;
   usQuoteClien = false;
   direct_debit_customer: any = [];
   isOrder:any;

   analysis_response: any;
   start_psicometrico: any;
   loading_analysis = false;

   contact_2:any;

   @Input() clientType:any;



   view:any= 1;
  constructor(

    private activatedRoute: ActivatedRoute,
    private router: Router,
    public _user: UserService,
    private sanitizer: DomSanitizer,
    private redirecFormService: RedirecFormService,
    private datePipe: DatePipe,
    private offcanvasService: NgbOffcanvas,
    public _auth: AuthService,

  ) { }

  ngOnInit(): void {



    this.getBase_amount();

    this._auth.auth$.subscribe((rep: any) => {

      let sessionUser = rep? this._auth.sessionUser: null;

      if(

        sessionUser?.username == 'juan@hazling.com' ||
        sessionUser?.username == 'diribarren' ||
        sessionUser?.username == 'asarkis@totalmundo.com' ||
        sessionUser?.username == 'jesuli.montenegro@hazling.com' ||
        sessionUser?.username == 'nathalyaespisona@totalmundo.com' ||
        sessionUser?.username == 'agregorio@totalmundo.com'

      ){
        this.supUse =true;

      }

      this.clientType = localStorage.getItem('clientType');

      this.contact_2 = this.person_types_code_description_obj[this.clientType];


 });

    this.activatedRoute.params.subscribe(params => {
      this.queryParams = params;
      if (this.queryParams?.code) {

        this.getPerson();
      }

    });

  }


  getBase_amount = async () =>  {
    try {

      let response =  await this._user.getBase_amount();
      this.base_amount = response?.amount;
    } catch (error) {

      console.log(error);

    }
  }


  getSection_transaction_by_person = async (buro:any = false) => {

    try {
      this.loading_buro = true;
      const resp = await this._user.getSection_transaction_by_person({ code: this.queryParams.code,type:'ONBOARDING' });

      let result = resp?.resp;

      if(result?.section_transactions?.length > 4){
        this.clientAprov = true;

    }


    result?.section_transactions.map((obj: any) => {

      let section_transaction = obj?.section_transaction;

    if (section_transaction?.code == "BURO" && obj.condition == "COMPLETE") {
      this.getDataBuroSimple(this.person, false);
    }
    return obj;
  });



    } catch (error) {

    }
    this.loading_buro = false;


    this.loading = false;

  }




  getDataBuroSimple = async (item: any, reload = true) => {

 this.loading_buro = true;
    if (item?.identitys?.length > 0) {

      let params = {
        "type_identity": item?.identitys[0]?.type_identity?.additionalType,
        "identity": item?.identitys[0]?.identity,
        "lastName": item?.person?.lastName,
        "code_person": item?.person?.code
      }

      try {
        const buro = await this._user.getDataBuro(params);

        this.buro = buro?.resp;

        if(this.buro.status_approved == "APPROVED"){

          this.clientAprovBuro= true;

        }
      } catch (error) {
        console.log(error);

      }

      this.loading_buro = false;


    }

  }


restClientBuro = async () =>  {


  Swal.fire({
    title: 'Alerta',
    text: '¿Estas seguro que deseas eliminar este cliente?',
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    showCancelButton: true,
    confirmButtonText: `Si!`,
    cancelButtonText: `Cancelar!`,
    showLoaderOnConfirm: true,
    allowOutsideClick: () => !Swal.isLoading(),
  }).then(async (result) => {
    if (result.isConfirmed) {


      let params = {

        "client": {
          "type_identity":this.person?.identitys[0]?.type_identity?.code ,
          "code_identity": this.person?.identitys[0]?.code_identity?.code,
          "identity":this.person?.identitys[0]?.identity
        }

      };

    try {

    await this._user.clientQBuroReset(params);
     alertifyjs.success(`Eliminacion exitosa`);

      this.router.navigate([`/console/create-constacts`], {});

     }  catch (error:any) {

      debugger

      alertifyjs.error(error?.error?.error);

         console.log(error);
  }


    }
  });



}

  getPerson = async () => {
    this.loading = true;

    try {
      const resp = await this._user.getPersonbyCode({ code: this.queryParams.code });

    this.getSection_transaction_by_person();

    this.person = resp.resp;

    if (this.person?.person?.params?.type?.includes('SUSCRIPTOR') ||
        this.person?.person?.params?.type?.includes('PROVIDER')
        || this.person?.person?.params?.type?.includes('PAYROLL')) {

      this.isOrder = true;


    }

    if (!this.person?.person?.params?.type || this.person?.person?.params?.type?.includes('CLIENT')) {

      this.isClient = true;

    }


    let customer_direct_debit = await this._user.customerDirectDebitProcesses(this.person.identitys[0].code_identity.name, this.person.identitys[0].identity);
      console.log(customer_direct_debit);
      customer_direct_debit.res.map((row: any)=> {
        if(row.invoice!==null){
          console.log(row);
          this.direct_debit_customer.push(row.invoice);
        }
      });
      //this.direct_debit_customer


    this.getAnalysis(this.person);

    this.invoicesPending();

    } catch (error) {
      console.log(error);

    }

    this.loading = false;


  }


  getIdentity(item: any) {

    if (!(item?.identitys?.length > 0))
      return "";

    return `${item?.identitys[0]?.type_identity?.name} ${item?.identitys[0]?.code_identity?.name}-${item?.identitys[0]?.identity}`;

  }




  sanitize(url: string) {
    //return url;
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }



  public redirect = () => {

    let Doc_ID;
    let Type_ID;
    let TLF;


    if (this.person?.identitys?.length > 0) {

      const data = this.person?.identitys[0];
      Type_ID = data.type_identity?.additionalType;
      Doc_ID = data.identity;

    }


    const data = {

      CED: environment.CED,
      IP: environment.IP_LOCAL,
      Type_ID,
      Doc_ID,
      PN: this.person?.person?.name,
      PA: this.person?.person?.lastName,
      FN: this.datePipe.transform(this.person?.person?.birth_date, 'yyyy-MM-dd'),
      GN: this.person?.person?.gender,
      TLF: "",
      EMAIL: ""

    }

    this.redirecFormService.postRedirect(environment.externalPsicometrico, data);

  }



  pocisionBottom = async (content: TemplateRef<any>,data:any= {}) =>  {

    try {
      this.loadQuota = true;

       let result =  await this.submit();
        this.offcanvasService.open(content, { position: 'bottom',keyboard: false} );

    } catch (error) {
      console.log(error);

    }

    this.loadQuota = false;

  }


  submit = async () =>  {

    let person  =this.person;

    return new  Promise( async (resolve, reject)  => {


      this.identityResponse = `${person?.identitys[0]?.code_identity?.name}-${person?.identitys[0]?.identity}`;

      let params = {code_person: person?.person?.code }


    try {
           let resp = await this._user.clientCountCreditPending(params);

      this.searchPerson =resp?.res?.person;

      this.usQuote = this.searchPerson?.quota_active;
      this.usQuoteClien = this.searchPerson?.quota_client_active;


      this.countInvoice = resp?.res?.countInvoice;


      resolve(true);


     }  catch (error:any) {
        console.log(error);

        reject(error);
     }

    });
}

offcanvasServiceClose(){
  this.offcanvasService.dismiss();

}


activeCupo = async (code:any) =>  {
  let params = {

    "code_person": code,
    "id_ejecutive":this._auth.sessionUser?.uid

  };

try {

  let resp = await this._user.activeCupo(params);
  this.getPerson();

 }  catch (error:any) {
     console.log(error);
}


}


manual_approve = async (code:any) =>  {

  Swal.fire({
    title: 'Alerta',
    text: '¿Estas seguro que deseas aprobar manualmente?',
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#d33',
    showCancelButton: true,
    confirmButtonText: `Si!`,
    cancelButtonText: `Cancelar!`,
    showLoaderOnConfirm: true,
    allowOutsideClick: () => !Swal.isLoading(),
  }).then(async (result) => {
    if (result.isConfirmed) {
      let params = {
        "codePerson": code
      };

      try {

        let resp = await this._user.manual_approve(params);
        alertifyjs.success(`Aprobación exitosa`);

        this.getPerson();


      }  catch (error:any) {
          console.log(error);
      }


    }
  });

}




toogleStatus = async (st = this.usQuote) => {

  let status = st? true:false;
  try {
    let query = {
      code_person:this.searchPerson?.code,
      status
    };

     await this._user.status_quote_user(query);
     alertifyjs.success('Cambio de estatus exitoso');

     this.getPerson();

  } catch (error) {

    this.usQuote = !this.usQuote;
    alertifyjs.error('Error al cambiar estatus');
    console.log(error);

  }


}


toogleStatusClient = async (st = this.usQuoteClien) => {

  let status = st?true:false;


  try {
    let query = {
      status,
      code_person: this.person?.person?.code
    };

    const resp = await this._user.toogleStatusClient(query);

    this.getPerson();

  } catch (error) {
    this.usQuoteClien = !status;
    console.log(error);

  }


}


invoicesPending = async () => {



  try {
              let params = {code_person: this.person?.person?.code };

      let resp = await this._user.clientCountCreditPending(params);

      this.searchPerson =resp?.res?.person;

      this.usQuote = this.searchPerson?.quota_active;
      this.usQuoteClien = this.searchPerson?.quota_client_active;


      this.countInvoice = resp?.res?.countInvoice;


  } catch (error) {
    console.log(error);

  }


}


getAnalysis = async (item: any, reload = true) => {

  try {

    let params = {
      codePerson: item?.person?.code
    };

    const analysis = await this._user.getAnalysis(params);

    let analysis_response =  (Object.keys(analysis?.result).length > 0 && analysis?.result?.constructor === Object)? analysis?.result : null;

      this.status_approved = analysis_response.approve_status;



} catch (error) {

  console.log(error);

}

}

change_quote = async () => {

  this.submit_disabled_quote = true;
  try {

    let params = {

      increment_type:'S',
      amount:this.new_quote,
      code_person:this.person?.person?.code,
    };

    const analysis = await this._user.update_quote_person(params);
    alertifyjs.success(`Cambio exitoso`);

    this.submit_disabled_quote = false;
    this.edit_quote= false;
    this.new_quote = ''

    this.getPerson();

} catch (error:any) {
  console.log(error);
  alertifyjs.error(error?.error?.msg);
  this.new_quote = ''
  this.submit_disabled_quote = false;


}

}
getEmailContact(item: any) {
  if (!(item?.constacts?.length > 0) || !(item?.constacts[1]))
    return "";

  return `${item?.constacts[1]?.contact}`;


}

setResetStatus(data: any) {

   //consultar de nuevo

}

editPaymentMethod(event: any) {
  //this.current_payment_method_id = event.id;
  // this.setChangView(event.view);
}


setChangView(view: any) {
  this.view = view;
}



// getAnalysis2 = async (item: any, reload = true) => {

//   try {
//     this.loading_analysis = true;

//     let params = {
//       codePerson: item?.person?.code
//     };

//     const analysis = await this._user.getAnalysis(params);

//     this.analysis_response =  (Object.keys(analysis?.result).length > 0 && analysis?.result?.constructor === Object)? analysis?.result : null;

//     if(this.analysis_response.transaction_status == 'INCOMPLETE'){
//       this.analysis_response.showStatusValue = 'Pendiente';
//   }else if(this.analysis_response.transaction_status == 'COMPLETE'){
//     this.analysis_response.showStatusValue = 'Completa';
//   }

//   this.status_approved = this.analysis_response.approve_status;

//   if(this.analysis_response.approve_status == 'REFUSED'){


//   this.analysis_response.showStatusApprove = 'Rechazado';

//   }else if(this.analysis_response.approve_status == 'APPROVED'){
//     this.analysis_response.showStatusApprove = 'Aprobado'
//   }else if(this.analysis_response?.approve_status == 'PENDING' && this.analysis_response?.next_approve_type == 'BURO_PSICOMETRICO'){

//       /*this.getScorePsicometrico(item, params);
//       let d = new Date(this.buro?.consult_psicometrico_date);
//       let dNew = new Date();

//       if (dNew > d) {
//       }
//       */

//       this.start_psicometrico = true;

//     this.analysis_response.showStatusApprove = 'En espera por psicometrico';

//     this.status_approved = 'BURO_PSICOMETRICO';

//   }




//     this.loading_analysis = false;

//     if (reload) {
//       this.getSection_transaction_by_person(true);
//     }
//   } catch (error) {

//     this.loading_analysis = false;


//   }

// };



// setAnalysis = async (item: any, reload = true) => {

//   try {
//     this.loading_analysis = true;
//     let params =
//     {
//     codePerson: item?.person?.code,
//     "external": false,
//     "updateScore": false,
//     "step": ""
//     }


//     const analysis = await this._user.setAnalysis(params);

//     this.analysis_response = analysis?.result;
//     this.getPerson();

//     this.loading_analysis = false;


//   } catch (error) {

//     this.loading_analysis = false;


//   }

// };

editBaseData(event: any) {
  //this.current_payment_method_id = event.id;
  this.setChangView(event.view);
}


setChangViewPerson(view: any) {

  this.getPerson();
  this.view = view;

}


}
