import { Component, OnInit, TemplateRef } from '@angular/core';
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as alertifyjs from 'alertifyjs';
import Swal from 'sweetalert2';
import { MassiveService } from '../../services/massive.service';
import { AuthService } from 'src/app/services/auth/auth.service';
import { UserService } from 'src/app/services/user/user.service';
import { DatePipe } from '@angular/common';
import { saveAs } from 'file-saver';
import { DirectDebitService } from 'src/app/services/direct-debit/direct-debit.service';

@Component({
  selector: 'app-dashboard-payment-orders-groups',
  templateUrl: './dashboard-payment-orders-groups.component.html',
  styleUrls: ['./dashboard-payment-orders-groups.component.css'],
  providers: [DatePipe]
})
export class DashboardPaymentOrdersGroupsComponent implements OnInit {
  
  startTraceDocument = true;
  startTraceDocumentDomiciliation = true;
  file: any;
  loading = true;
  sessionUser: any;
  profiles: any;
  profile: any;
  current_process: any;
  status_sections: any = [];
  current_status_sections: any = [];
  loading_section: any = [];
  show_conciliacion_button = false;
  obj_conciliacion: any;
  provider: any = {
    tax_id_letter: 'J',
    tax_id: '311501878',
    tax_id_verification: '0',
  };
  template: string = '/lotes/bdv/send_final';
  sections: any = [
    {
      id: 1,
      name: '<b>Cargar archivo de procesamiento másivo</b><br><small> El archivo debe contener (codigo_pago, accion, observaciones)<br>Formatos válidos: xlsx, xls<small>',
      status: 'INITIAL',
    },
    {
      id: 2,
      name: 'Resumen del proceso',
      status: 'FINISHED',
    }
  ];

  types: any = [
    {
      id: 'MASSIVE_CONCILIATION',
      name: 'Conciliación másiva',
    }
  ];

  banks: any = [];

  pending: any;

  view = 1;
  form: FormGroup | any = this.formBuilder.group({
    bank: [''],
    type: [''],
  });
  files: any = [];

  date: any = new Date();
  current_date: any = this.datePipe.transform(new Date(), 'yyyy-MM-dd');

  btn_restart = false;
  btn_restart_conciliation = false;
  response_trace_conciliation: any;

  config_read: any;


  constructor(
    private userService: UserService,
    private offcanvasService: NgbOffcanvas,
    private formBuilder: FormBuilder,
    private massiveService: MassiveService,
    private directDebitService: DirectDebitService,
    public _auth: AuthService,
    private datePipe: DatePipe
  ) {}

  async ngOnInit() {
    this.sessionUser = this._auth.sessionUser;
    this.profiles = this._auth.getProfiles();
    this.profile = this.getProfile(this.sessionUser);
    this.banks = await this.getBanks();
    this.banks = this.banks.res;
    this.setDefault();
    this.updateCurrentProcess();
    this.config_read = await this.getConfig('read');
    this.loading = false;
  }

  getConfig = (type: string): Promise<any> => {
    let bank = this.form.getRawValue().bank;
    return this.massiveService.getConfig("68042cb3-043d-4748-a712-0f5ec5997324", type, 'MASIVE_UPLOAD');
  };

  getCurrentDate = async () => {
    this.pending = await this.massiveService.pendingLote();
    if (this.pending.resp.length > 0) {
      this.date = this.datePipe.transform(
        this.pending.resp[0].date,
        'yyyy-MM-dd'
      );
    } else {
      this.date = this.datePipe.transform(new Date(), 'yyyy-MM-dd');
    }
  };

  getProfile(user: any) {
    let profiles = user?.profile;
    let profile;
    if (profiles) {
      let value = this.profiles[profiles[0]];
      let key = profiles[0];
      profile = { key, value };
    }
    return profile;
  }

  updateStatusSections = async () => {
    await this.getStatusCurrentMassiveProcess();
    let iter_section;
    this.sections.map((section: any) => {
      this.status_sections[section.status] = 'PENDING';
      iter_section = this.validateSection(section.status);
      if (iter_section) {
        this.status_sections[section.status] = iter_section;
      }
    });
    this.loading = false;
  };

  validateSection = (status: string) => {
    return this.current_status_sections.find((section: any) => {
      return section.status === status;
    });
  };



  // updateCurrentProcess = async (direct_debit: any) => {
  //   this.getCurrentDate();
  //   //this.current_process = null;
  //   this.files = [];
  //   if(direct_debit) {
  //     this.current_process = direct_debit;

  //   } else {
  //     this.current_process = null;
  //   }
  //   this.updateStatusSections();
  // };

  updateCurrentProcess = async () => {
    this.getCurrentDate();
    
    this.files = [];
    this.current_process = await this.getMassiveProcessByBankAndType(
      this.form.getRawValue().bank,
      this.form.getRawValue().type,
      this.date,
      null,
      null
    );

    if (this.current_process) {
      if (this.current_process.res) {
        if (this.current_process.res.length > 0) {
          this.current_process = this.current_process.res[0];
        } else {
          this.current_process = null;
        }
      } else {
        this.current_process = null;
      } 
    } else {
      this.current_process = null;
    }

    this.updateStatusSections();
    this.startTraceDocument =false;
    setTimeout(()=> { this.startTraceDocument = true }, 5);

    this.startTraceDocumentDomiciliation =false;
    setTimeout(()=> { this.startTraceDocumentDomiciliation = true }, 5);

  }

  setResponse = (elements: any[], type: string) => {
    console.log('Esta es la respuesta');
    console.log(elements);
    if(elements.length>0) {
      if(elements[0].state==="ERROR" && type==="START") {
        this.btn_restart = true;
      }else if(elements[0].state==="ERROR" && type==="CONCILIATION") {
        this.btn_restart_conciliation = true;
        this.response_trace_conciliation = elements;
      } else if(type==="CONCILIATION") {
        this.response_trace_conciliation = elements;
      }
    }
  }


  getStatusCurrentMassiveProcess = async () => {
    if (this.current_process) {
      this.current_status_sections = await this.getMassiveProcessStatuses(
        this.current_process._id
      );
      this.current_status_sections = this.current_status_sections.res;
    } else {
      this.current_status_sections = [];
    }
  };

  setDefault = () => {
    if (this.banks.length === 1) {
      this.form.get('bank').setValue(this.banks[0].code);
      this.form.get('bank').patchValue(this.banks[0].code);
    }
    if (this.types.length >= 1) {
      this.form.get('type').setValue(this.types[0].id);
      this.form.get('type').patchValue(this.types[0].id);
    }
  };

  getMassiveProcessByBankAndType = (
    bank: string,
    type: string,
    date: any,
    suscriptor: any,
    account: any
  ): Promise<any> => {
    return this.massiveService.getMassiveProcess(bank, type, date, suscriptor, account);
  };

  getMassiveProcessStatuses = (direct_debit_id: string): Promise<any> => {
    return this.massiveService.getMassiveProcessStatuses(direct_debit_id);
  };

  getBanks = async () => {
    return await this.userService.getBanks({});
  };

  openModalBanks = (content: TemplateRef<any>, data: any = {}) => {
    try {
      this.offcanvasService.open(content, {
        position: 'bottom',
        keyboard: false,
      });
    } catch (error) {
      console.log(error);
    }
  };

  pendingDirectDebit = async () => {
    return await this.massiveService.pendingLote();
  };

  


  downloadCheck = async () => {
    if(this.status_sections['FINISHED']==='PENDING' && this.status_sections['PROCESING']==='PENDING' && this.status_sections['UPLOADED']==='PENDING'){
      let body = {
        direct_debit_id: this.current_process._id,
        status: 'DOWNLOADED',
        user: this.sessionUser.uid,
      };
      console.log(body);
      await this.directDebitService.saveDirectDebitStatus(body);
      await this.updateCurrentProcess();
    }
  };

  offcanvasServiceClose() {
    this.view = 1;
    this.offcanvasService.dismiss();
  }

  reset = (evt: any) => {
    this.show_conciliacion_button = false;
    this.obj_conciliacion = null;
  };

  getResponse = (response: any) => {
    if (response) {
      this.show_conciliacion_button = true;
      this.obj_conciliacion = response.response;
      this.file = response.file;
    } else {
      this.show_conciliacion_button = false;
      this.obj_conciliacion = null;
      this.file = null;
    }
  };

  procesar = async () => {
      // este el el loading del botón
      this.loading_section['INITIAL'] = true;


      // objeto requerido por el servicio de conciliación
      let obj = {
        config: this.config_read.config,
        bank: this.form.getRawValue().bank,
        type: this.form.getRawValue().type,
        url: this.obj_conciliacion.file.url
      };

      debugger;

      try {
        try{


          // este es el único servicio encargado de la conciliación Juan
          let response = await this.massiveService.conciliar(JSON.stringify(obj), this.file, null);

          if(response) {
            if(!response.success) {
              alertifyjs.warning(
                response.description
              );
            } 
          }
          
          await this.updateCurrentProcess();
          // se desmarca el proceso de conciliación activo
          this.show_conciliacion_button = false;
          this.loading_section['INITIAL'] = false;
          

        } catch(e) {
          alertifyjs.error(
            'Ha ocurrido un error en el proceso de conciliación, contacte con el administrador o reintente conciliar.' + e
          );
          this.show_conciliacion_button = true;
          this.loading_section['INITIAL'] = false;
        }
      } catch (error) {
        console.log(error);
        this.show_conciliacion_button = true;
        this.loading_section['INITIAL'] = false;
        alertifyjs.error(
          'Ha ocurrido un error en el proceso de conciliación, contacte con el administrador o reintente conciliar.' + error
        );
      }
    
  };

 
  formatDataToExcel = (obj: any) => {
    let response: any = [];

    obj.map((element: any) => {
      console.log(element);
      response = [
        ...response,
        {
          CEDULA: element.identity,
          CREDITO: element.num_credit,
          REFERENCIA: element.reference,
          'NUMERO DE CUENTA': element.num_cuenta,
          'PROCESADO POR EL BANCO': element.success ? 'SI' : 'NO',
          DESCRIPCION: element.description,
          CONCILIADO: element?.conciliation?.reconciled_payment ? 'SI' : 'NO',
          MONTO: element.conciliation
            ? this.toFixedApp(
                element.conciliation.amount * element.conciliation.rate
              )
            : 0,
        },
      ];
    });
    return response;
  };

  toFixedApp = (x: any) => {
    let a: any = `e+${2}`;
    let b: any = `e-${2}`;
    return +(Math.round(x + a) + b);
  };

  findConciliationObject = (obj: any, reference: any) => {
    return obj.find((element: any) => element.paymentMethodId === reference);
  };

  getElements = (obj: any, type: any) => {
    let response: any = [];
    obj
      .filter((row: any) => row.TIPOREG !== '01')
      .map((row: any) => {
        if (row.TIPOREG === '02') response = [...response, row];
        if (row.TIPOREG === '03') {
          let position = response.findIndex((ele: any) => {
            return ele.REFDEB === row.REFDEB && !ele.ERROR;
          });
          if (position != -1) {
            console.log(response[position]['TOTCOB']);
            if(response[position]['TOTCOB']==="0.00"){
              response[position]['ERROR'] = row;
            }
          }
        }
      });
    return response;
  };

  confirm_upload = async () => {
    Swal.fire({
      title: 'Confirmación',
      text: 'Solo debe confirmar cuando el archivo se haya recibido de forma exitosa por el banco ¿Desea confirmar?',
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      showCancelButton: true,
      confirmButtonText: `Si!`,
      cancelButtonText: `Cancelar!`,
      showLoaderOnConfirm: true,

      allowOutsideClick: () => !Swal.isLoading(),
    }).then(async (result) => {
      if (result.isConfirmed) {
        this.loading_section['UPLOADED'] = true;
        let body_2 = {
          direct_debit_id: this.current_process._id,
          status: 'UPLOADED',
          user: this.sessionUser.uid,
          url: undefined,
        };
        // await this.massiveService.saveDirectDebitStatus(body_2);
        this.loading_section['UPLOADED'] = false;
        await this.updateCurrentProcess();
      }
    });
  };

  cancel_process = () => {
    Swal.fire({
      title: 'Alerta',
      text: '¿Desea cancelar el proceso, esta acción no se puede revertir, desea continuar?',
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      showCancelButton: true,
      confirmButtonText: `Si!`,
      cancelButtonText: `Cancelar!`,
      showLoaderOnConfirm: true,
      allowOutsideClick: () => !Swal.isLoading(),
    }).then(async (result) => {
      if (result.isConfirmed) {
        this.loading_section['UPLOADED'] = true;
        let body = {
          id: this.current_process._id,
          user: this.sessionUser.uid,
        };
        // await this.massiveService.cancel_direct_debit(body);
        this.loading_section['UPLOADED'] = false;
        await this.updateCurrentProcess();
      }
    });
  };

  restart_process_conciliation = async() => {
    let body = {
      trace: "DIRECTDEBIT_GENERATE_CONCILIATION_"+this.current_process.trace
    };
    // await this.massiveService.cancel_direct_debit_trace(body);
    this.btn_restart_conciliation = false;
    this.response_trace_conciliation = null;
    await this.updateCurrentProcess();
  };

  restart_process = async() => {
    this.btn_restart = false;
    this.loading_section['UPLOADED'] = true;
    let body = {
      id: this.current_process._id,
      user: this.sessionUser.uid,
    };
    // await this.massiveService.cancel_direct_debit(body);
    this.loading_section['UPLOADED'] = false;
    await this.updateCurrentProcess();
  };

  getHeader = (obj: any, type: any) => {
    let response: any = [];
    return obj.filter((row: any) => row.TIPOREG === '01');
  };

  formatElements = (elements: any) => {
    let response: any = [];
    let element: any;
    elements.map((ele: any) => {
      element = {
        res: ele.TIPOREG,
        success: ele.ERROR ? false : true,
        identity: ele.CEDULA,
        num_cuenta: ele.NROCTA1,
        reference: ele.REFDEB,
        amount: ele.TOTCOB,
        description: ele.DESCSTA,
        num_credit: ele.REFDEB.replace(/^0+/, '').slice(0, -1),
        error: ele.ERROR ? ele.ERROR : undefined,
      };
      response = [...response, element];
    });
    return response;
  };

  downloadFile = async (url:any, extension: string, name: string) => {
    let res = await this.directDebitService.downloadImage(url, extension);
    this.downloadCheck();
    if(res) {
      saveAs(res, `${name}.${extension}`);
    }
  }

  finalizar = async() => {
    let body = {
      id: this.current_process._id
    };
    await this.massiveService.finish(body);
    this.updateCurrentProcess();
  }

}
