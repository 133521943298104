<ng-template #content let-offcanvas>
    <div class="offcanvas-header">
        <div class="container ">
            <div class="row">
                <div class="col-12 col-md-6 offset-md-3 ">
                    <div class="row">
                        <div class="col-10">
                            <div class="text-capitalize h5 text-secondary mt-2 ">
                                {{user_options?.person?.name}} {{user_options?.person?.lastName}}
                            </div>
                        </div>
                        <div class="col-2 text-end pt-2">
                            <button type="button" class="btn-close" aria-label="Close" (click)="offcanvas.dismiss('Cross click')"></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>


    <div class="offcanvas-body">
        <div class=" container">

            <div class="row">
                <div class=" col-12 col-md-6 offset-md-3 mt-4">
                    <!-- <pre>{{ user_options | json }}</pre> -->


                    <div class="d-flex justify-content-between">

                        <div>
                            <div class=" fw-normal ">
                                Estatus <span class="text-secondary small">({{usQuote?"Activo":"Inactivo"}})</span>
                            </div>
                        </div>

                        <div>
                            <ng-toggle (change)="toogleStatus()" [(ngModel)]="usQuote" [value]="true" [color]="{
                                checked: '#01abce',
                                unchecked: '#dcdcdc'
                            }"></ng-toggle>

                        </div>


                    </div>

                    <div class="d-flex justify-content-between mt-4">

                        <div class="input-group ">

                            <div class="form-floating">
                                <select [(ngModel)]="shop" class="form-select" id="gender" aria-label="Floating label select example">
                                    <option  value="">Selecione</option>
                                    <option *ngFor="let item of shops"  [value]="item.code">{{item.name}}</option>

                                </select>
                                <label for="gender">Tienda</label>
                            </div>
                            <button (click)="changeShop()" [disabled]=" submit_disabled_shop" class="btn btn-primary">
                                Guardar
                                <span *ngIf="submit_disabled_shop">
                                <i class="fa  fa-spinner fa-spin"></i>
                                </span>
                            </button>

                        </div>

                    </div>

                    <div class="d-flex justify-content-between mt-4">

                        <div class="input-group">

                            <div class="form-floating ">
                                <input type="password" [(ngModel)]="password" class="form-control " id="contact" placeholder="******">
                                <label for="contact" class=" label font-weight-normal" style="word-wrap: break-word;">
                                    <span >Nuevo Password</span>
                                </label>
                            </div>
                            <button (click)="changePass()" [disabled]="!password || submit_disabled_contact" class="btn btn-primary">
                                Cambiar
                                <span *ngIf="submit_disabled_contact">
                                <i class="fa  fa-spinner fa-spin"></i>
                                </span>
                            </button>

                        </div>

                    </div>

 <div class="d-flex justify-content-between mt-4">
  <div class="input-group">

    <div class="form-floating">
      <select class="form-select" [(ngModel)]="selectedRole" id="availableRoles">
        <option value="">Seleccione</option>
        <optgroup label="Credito">
          <option *ngIf="!selectedProfiles.includes('Owner')" value="OWNER">Owner</option>
          <option *ngIf="!selectedProfiles.includes('Analista')" value="ANALIST">Analista</option>
          <option *ngIf="!selectedProfiles.includes('Promotor')" value="EJECUTIVE">Promotor</option>
          <option *ngIf="!selectedProfiles.includes('Caja')" value="SHOP">Caja</option>
        </optgroup>
            <optgroup label="Ordenes de Pago">
                <option *ngIf="!selectedProfiles.includes('Solicitud de Pago')" value="PAYMENT_REQUEST">Solicitud de Pago</option>
                <option *ngIf="!selectedProfiles.includes('Revisión y Aprobación de la Solicitud de Pago')" value="REVIEW_AND_APPROVAL_OF_PAYMENT_REQUEST">Revisión </option>
                <option *ngIf="!selectedProfiles.includes('Autorización del Pago')" value="PAYMENT_AUTHORIZATION">Autorización del Pago</option>
                <option *ngIf="!selectedProfiles.includes('Ejecución del Pago')" value="PAYMENT_EXECUTION">Ejecución del Pago</option>
                <option *ngIf="!selectedProfiles.includes('Ejecución del Pago')" value="AUTHORIZATION_PAYMENT_EXECUTION"> Aprobación en Banco</option>

                <option *ngIf="!selectedProfiles.includes('Conciliación y auditoria')" value="RECONCILIATION_AND_AUDIT">Conciliación y auditoria</option>
                <option *ngIf="!selectedProfiles.includes('Auditoría')" value="AUDIT">Auditoría</option>
                <option *ngIf="!selectedProfiles.includes('Archivo y Documentación')" value="FILING_AND_DOCUMENTATION">Archivo y Documentación</option>
            </optgroup>
      </select>
      <label for="availableRoles" class="label font-weight-normal">
        <span>Perfil</span>
      </label>
    </div>

    <button class="btn btn-primary" (click)="addRoleAndSave()" [disabled]="!selectedRole">
        <span *ngIf="add_loading">
            <i class="fa fa-spinner fa-spin"></i>
        </span>
        <span *ngIf="!loading">Agregar</span>
    </button>

  </div>
</div>

<div class="pb-4 mb-0 small lh-sm border-bottom w-100"></div>

<div class="mt-3">
  <div class="main-card">
    <h6 class="border-bottom pb-3 pt-1 mb-0 fw-bold">
      Perfiles asociados
    </h6>
    <div class="card-container">
      <div *ngFor="let role of selectedProfiles">
  <div class="pb-4 pt-4 mb-0 small lh-sm border-bottom w-100">
    <div class="card-content">
      <span>{{ role }}</span>
      <span *ngIf="delete_loading">
            <i class="fa fa-spinner fa-spin"></i>
        </span>
        <span *ngIf="!delete_loading">
            <i class="far fa-trash-alt text-danger" style="font-size: 18px; cursor: pointer !important;" (click)="removeRole(role)"></i>
        </span>
    </div>
  </div>
</div>
    </div>
  <div *ngIf="!loading && selectedProfiles.length === 0" class="empty-state mt-4">
                        <p>Este usuario no tiene un perfil asociado.</p>
                    </div>
</div>
</div>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<div class="container">
        <div class="mt-md-3 p-3">
    <div class="row">
        <div class="col-md-3 order-md-last">
                <h5 class="mb-4 text-secondary mb-5 text-center">
                    <i class="fas fa-filter"></i> Filtros
                </h5>
                
                <div class="form-group col-12 mt-5 mb-3">
                    <div class="input-group">
                        <div class="form-floating">
                            <input type="text" [(ngModel)]="search_form" class="form-control" id="search" placeholder="name@example.com" (keyup.enter)="getUsers(true, true)">
                            <label for="search" class="label font-weight-normal" style="word-wrap: break-word;">
                                <span>Nombre y Apellido</span>
                            </label>
                        </div>
                        <button (click)="getUsers(true, true)" [disabled]="submit_disabled" class="btn btn-dark">
                            Buscar
                            <span *ngIf="submit_disabled">
                                <i class="fa fa-spinner fa-spin"></i>
                            </span>
                        </button>
                    </div>
                </div>

            
                <div class="checkbox-container">
                    <button (click)="toggleCheckboxes()" class="filter">
                        {{ showCheckboxes ? 'Perfiles' : 'Perfiles' }}
                        <i class="fas text-primary" [ngClass]="{'fa-arrow-down': !showCheckboxes, 'fa-arrow-up': showCheckboxes}"></i>
                    </button>

                    <div *ngIf="showCheckboxes" class="dropdown-list mt-3">
                        <div *ngFor="let role of roleMapping | keyvalue">
                            <input
                                type="checkbox"
                                [(ngModel)]="roleSelection[role.key]"
                                (change)="onRoleChange()"
                            />
                            <label>{{ role.value }}</label>
                        </div>
                    </div>
                </div>

            <div class="checkbox-container mt-3 mb-5">
                <button (click)="toggleShopCheckboxes()" class="filter">
                    {{ showShopCheckboxes ? 'Tiendas' : 'Tiendas' }}
                    <i class="fas text-primary" [ngClass]="{'fa-arrow-down': !showShopCheckboxes, 'fa-arrow-up': showShopCheckboxes}"></i>
                </button>

                <div *ngIf="showShopCheckboxes" class="dropdown-list mt-3">
                    <div *ngFor="let shop of shops">
                        <input
                            type="checkbox"
                            [(ngModel)]="shopSelection[shop.code]"
                            (change)="onShopChange()"
                        />
                        <label>{{ shop.name }}</label>
                    </div>
                </div>
            </div>
        </div>
            
            <div class="col-md-8">
                <h5 class="mb-4 text-secondary mb-5 text-center">
                    <i class="fas fa-users"></i> Usuarios
                </h5>

                <div class="d-flex justify-content-between">
                    <div class="pt-3">
                        <span class="pe-2 text-secondary cursor-pounter" [ngClass]="{'fw-bold': status_users}" (click)="status_users = true;getUsers(true,true)"> Activos</span>
                        <span class="text-secondary cursor-pounter" [ngClass]="{'fw-bold': !status_users}" (click)="status_users = false;getUsers(true,true)"> Inactivos</span>
                    </div>
                    <div class="mb-2 text-end">
                        <button class="btn btn-primary" [routerLink]="['/register']">Nuevo Usuario</button>
                    </div>
                </div>

                <div *ngIf="people" class="mt-3 pt-4 my-3 p-3 bg-body rounded shadow-sm">
                    <h6 class="border-bottom pb-2 mb-0">Nombre
                        <div class="float-end text-secondary"> {{rows.length}} de {{people_total}}</div>
                    </h6>
                    <div class="search-results">
                        <ng-container>
                            <div *ngFor="let item of rows; let i=index" class="d-flex text-muted pt-4">
                                <div *ngIf="item?.person?.file_selfie">
                                    <div [ngClass]="{'active': usersActives[item?.person?.code]}" style="background-image: url({{item?.person?.file_selfie?.url}});" class="flex-shrink-0 me-2 base-img"></div>
                                </div>
                                <div *ngIf="!item?.person?.file_selfie">
                                    <div *ngIf="item?.user?.role?.role == 'COMPUTER_ROLE'" [ngClass]="{'active': usersActives[item?.person?.code]}" style="background-image: url(../assets/public/images/tablet.png);" class="flex-shrink-0 me-2 base-img"></div>
                                    <div *ngIf="item?.user?.role?.role == 'USER_ROLE'" [ngClass]="{'active': usersActives[item?.person?.code]}" style="background-image: url(../assets/public/images/avatar.png);" class="flex-shrink-0 me-2 base-img"></div>
                                </div>

                                <div class="pb-3 mb-0 small lh-sm border-bottom w-100">
                                    <div class="d-flex justify-content-between mt-2 mb-2">
                                        <div>
                                            <span class="text-dark fw-bold text-capitalize">{{item?.person?.name}} {{item?.person?.lastName}}</span>
                                        </div>
                                        <span>
                                            <a class="text-primary cursor-pointer" (click)="openBottom(content, item)">
                                                <i class="fas fa-ellipsis-v ps-2"></i>
                                            </a>
                                        </span>
                                    </div>
                                    <div class="mt-2 mb-2">
                                        <div class="text-dark mb-2"> {{item?.user?.username}} </div>
                                    </div>

                                    <div class="d-flex justify-content-between mt-2 small">
                                        <div>{{obj_shop[item?.user?.shop]?.name}}</div>
                                    </div>

                                    <div class="mt-3 mb-2">
                                        <ng-container *ngIf="getProfile(item?.user) as profiles">
                                            <ng-container *ngFor="let profile of profiles; let i = index">
                                                <span class="text-dark fw-bold">Perfil:</span>
                                                {{ profile?.value }}
                                                <ng-container *ngIf="i < profiles.length - 1">, </ng-container>
                                            </ng-container>
                                        </ng-container>
                                    </div>

                                    <div class="mt-3 mb-2">
                                        <span [ngClass]="{'text-bg-dark': item?.user?.status, 'text-bg-secondary': !item?.user?.status}" class="badge me-2">{{item?.user?.status ? 'Activo' : 'Inactivo'}}</span>
                                        <span *ngIf="status_users" [ngClass]="{'text-bg-success': usersActives[item?.person?.code], 'text-bg-danger': !usersActives[item?.person?.code]}" class="badge">{{usersActives[item?.person?.code] ? 'Online' : 'Offline'}}</span>
                                    </div>
                                </div>
                            </div>

                            <div *ngIf="hasMoreResults() && !loading" class="mt-3 text-center">
                                <button (click)="nextRows()" [disabled]="reload_loading_pluss" class="btn btn-primary">
                                    Siguiente
                                    <span *ngIf="reload_loading_pluss">
                                        <i class="fa fa-spinner fa-spin"></i>
                                    </span>
                                </button>
                            </div>
                        </ng-container>
                    </div>

                    <div *ngIf="!loading && rows.length === 0" class="empty-state mt-4">
                        <p>No se encontraron resultados para la búsqueda.</p>
                    </div>
                </div>

                <ng-container *ngIf="loading">
                    <div class="w-100 text-center mt-2">
                        <img src="./assets/public/images/loading.gif" alt="">
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</div>
