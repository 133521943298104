<div class="mt-md-3 container-fluid">

    <div class="row mt-2">
        <div class=" col-12 col-md-8 offset-md-2">
            <div class="row mt-2">
                <div class="col-12  col-md-8 offset-md-2">
                    <h5 class=" text-secondary text-center">
                        <i class="  fas fa-user"></i> Estadisticas de Ordenes de Pago
                    </h5>
                </div>
            </div>
            <ng-container>
                <div class="row" *ngIf="!loading">

                    <div class="col-12  col-md-10 offset-md-1">
                      
                        <div class="row">
                            <div class="mb-4 mt-2 col-12">

                                <div class="row" *ngIf="stadistic">
                                    <div class="col-6 col-md-4 mt-4">
                                        <div class="card"  [routerLink]="['/console/order/dashboard-profile']" >
                                            <div class="card-body">
                                                <h5 class="card-title text-secondary">Entrada ({{stadistic?.inSteps?.length  > 0 ?  stadistic?.inSteps[0]?.count:0 }})</h5>

                                                <div class="mt-3 fw-bold">
                                                   USD {{(stadistic?.inSteps?.length  > 0 ?  stadistic?.inSteps[0]?.totalAmount:0)  | currency:'USD':'': '1.2-2' }} 
                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                    <h2 class="mt-4 mb-0 pb-0">Instantanea</h2>

                                    <h4 class="text-secondary">Todos mis subscriptores</h4>

                                    <div class="row" *ngIf="stadistic">
                                        <div class="col-6 col-md-4 mt-4">
                                            <div class="card" >
                                                <div class="card-body">
                                                    <h5 class="card-title text-success">Aprobados ({{stadistic?.aprobados?.length  > 0 ?  stadistic?.aprobados[0]?.count:0 }})</h5>
    
                                                    <div class="mt-3 fw-bold">
                                                       USD {{ (stadistic?.aprobados?.length  > 0 ?  stadistic?.aprobados[0]?.totalAmount:0)  | currency:'USD':'': '1.2-2' }} 
                                                    </div>
    
                                                </div>
                                            </div>
                                    </div>

                                    <div class="col-6 col-md-4 mt-4">
                                        <div class="card" >
                                            <div class="card-body">
                                                <h5 class="card-title text-danger">Devueltos ({{stadistic?.devueltos?.length  > 0 ?  stadistic?.devueltos[0]?.count:0 }})</h5>

                                                <div class="mt-3 fw-bold">
                                                   USD {{ (stadistic?.devueltos?.length  > 0 ?  stadistic?.devueltos[0]?.totalAmount:0)  | currency:'USD':'': '1.2-2' }} 
                                                </div>

                                            </div>
                                        </div>
                                </div>

                                <div class="col-6 col-md-4 mt-4">
                                    <div class="card" >
                                        <div class="card-body">
                                            <h5 class="card-title text-secondary">Pagados ({{stadistic?.pagados?.length  > 0 ?  stadistic?.pagados[0]?.count:0 }})</h5>

                                            <div class="mt-3 fw-bold">
                                               USD {{(stadistic?.pagados?.length  > 0 ?  stadistic?.pagados[0]?.totalAmount:0)  | currency:'USD':'': '1.2-2' }} 
                                            </div>

                                        </div>
                                    </div>
                            </div>


                            <div class="row" >
                                <div class="col-12 col-md-8 mt-4">

                                    <div class="card" >
                                        <div class="card-body">
                                            <h4 class="fw-bold mt-3 mb-3">Recibido autorizados</h4>

                                            <div class="d-flex">

                                                <div >
                                                    <div class="card-title text-secondary">Últimos 7d ({{stadistic?.siete?.length  > 0 ?  stadistic?.siete[0]?.count:0 }})</div>

                                                </div>
                                                <div class="ms-auto fw-bold">
                                                    USD {{(stadistic?.siete?.length  > 0 ?  stadistic?.siete[0]?.totalAmount:0)  | currency:'USD':'': '1.2-2' }} 

                                                </div>
                                            </div>

                                            <div class="d-flex mt-2">

                                                <div >
                                                    <div class="card-title text-secondary">Últimos 30d ({{stadistic?.trenta?.length  > 0 ?  stadistic?.trenta[0]?.count:0 }})</div>

                                                </div>
                                                <div class="ms-auto fw-bold">
                                                    USD {{(stadistic?.trenta?.length  > 0 ?  stadistic?.trenta[0]?.totalAmount:0) | currency:'USD':'': '1.2-2'  }} 

                                                </div>
                                            </div>


                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row" >
                                <div class="col-12 col-md-8 mt-4">

                                    <div class="card" >
                                        <div class="card-body">
                                            <h4 class="fw-bold mt-3 mb-4">Flujo de trabajo de pagos</h4>

                                            <div class="d-flex mt-2" *ngFor="let item of steps; let i=index">

                                             <div>
                                                    <div class="card-title text-secondary"> {{item?.name}} ({{stepObjData[item.code]?.count>0?stepObjData[item.code]?.count:0  }})</div>

                                                </div>
                                                <div class="ms-auto fw-bold">
                                                    USD {{ (stepObjData[item.code] ? stepObjData[item.code]?.totalAmount: 0)  | currency:'USD':'': '1.2-2'}} 

                                                </div>
                                            </div>


                                            <div class="d-flex mt-2"  *ngIf="stepObjData[''] ">
                                                <div >
                                                    <div class="card-title text-secondary"> Finalizados ({{stepObjData['']?.count }})</div>

                                                </div>
                                                <div class="ms-auto fw-bold">
                                                    USD {{ (stepObjData['']?stepObjData['']?.totalAmount:0)  | currency:'USD':'': '1.2-2'}} 

                                                </div>
                                            </div>

                                            

                                            
                                            <div class="d-flex mt-3">

                                                <div>
                                                    <div class="card-title text-secondary fw-bold">Total ({{ stadistic?.steps[0]?.overallCount }})</div>

                                                </div>
                                                <div class="ms-auto fw-bold h5">
                                                    USD {{stadistic?.steps[0]?.overallTotalAmount}} 

                                                </div>
                                            </div>


                                        </div>
                                    </div>
                                </div>
                            </div>
                               
                            <h2 class="mt-4 mb-0 pb-0">Autorizaciones recientes (0)</h2>


                                </div>



                                    

                                </div>



                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>

            <ng-container *ngIf="loading">
                <div class="w-100 text-center mt-2">
                    <img src="./assets/public/images/loading.gif" alt=" ">
                </div>
            </ng-container>

        </div>
    </div>
</div>