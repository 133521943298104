<div class="mt-md-3 container-fluid">
    <div class="row mt-2">
      <div class="col-12 col-md-8 offset-md-2">
        <div class="row rmt-2">
          <div class="col-12 col-md-12">
            <div class="h4 text-dark mt-5 mb-2">
              <span
                [routerLink]="['/console/dashboard-massive-payments']"
                class="fz-16 fas fa-arrow-left me-2"
              >
              </span>
  
              <span class="text-primary">{{ "Histórico de pagos masivos" }}</span>
            </div>
          </div>
        </div>
  
        <div class="row">
          <div class="cursor-pounter mt-3 my-3 p-3 bg-body rounded shadow-sm">
            <div class="col-12 text-end pt-2">
              <div class="col-12 col-md-12 mt-2">
                <input
                  style="padding: 16px 9px; background: white"
                  id="demo-external"
                  (onSet)="updateCurrentDirectDebit()"
                  [(ngModel)]="range"
                  mbsc-range
                  class="form-control"
                  [mbsc-options]="dateSetting"
                  #externalRange="mobiscroll"
                />
              </div>
  
              <form
                autocomplete="nope"
                novalidate
                [formGroup]="form"
                class="text-start"
              >
                <div class="row mt-2">
                  <div class="form-group col-12 mt-4">
                    <div class="form-floating">
                      <select
                        class="form-select"
                        id="bank"
                        formControlName="bank"
                        aria-label="Floating label select example"
                        (change)="updateCurrentDirectDebit()"
                      >
                        <option
                          *ngFor="let item of banks; let i = index"
                          [value]="item?.code"
                        >
                          {{ item.name }}
                        </option>
                      </select>
                      <label for="origin_account">Entidad Bancaria</label>
                    </div>
                  </div>
  
                </div>



                <div class="row mt-2">
                    <div class="form-group  col-12  "   >
                        <div class="form-floating">
                            <select class="form-select" id="suscriptor" formControlName="suscriptor" aria-label="Floating label select example" (change)="getAccountsSuscriptor();updateCurrentDirectDebit()">
                                    <option value="" selected="selected">-- Seleccione --</option>
                                    <option *ngFor="let item of accounts; let i=index" [value]="item?.person?.code">
                                        {{ item?.person?.name }} {{ item?.person?.lastName }}
                                    </option>
                            </select>
                            <label for="origin_account">Emisor - Organización</label>
                        </div>           
                    </div>

                   


                </div>


                <div class="row mt-2">
                    <div class="form-group  col-12  "   >
                        <div class="form-floating">
                            <select class="form-select" id="account" formControlName="account" aria-label="Floating label select example" (change)="updateCurrentDirectDebit()">
                                    <option value="" selected="selected">-- Seleccione --</option>
                                    <option *ngFor="let item of accounts_suscriptor; let i=index" [value]="item?._id">
                                        {{ item?.payment_method?.name }} - {{ item?.bank?.name }} - {{ item?.account_number }}
                                    </option>
                            </select>
                            <label for="origin_account">Cuenta bancaria</label>
                        </div>           
                    </div>

                   


                </div>
              </form>
              <!-- <button type="button" class="btn-close" aria-label="Close" (click)=" view > 1 ? view = 1 : offcanvas.dismiss('Cross click'); "></button> -->
            </div>
          </div>
        </div>
  
        <div class="row">
          <div class="cursor-pounter mt-3 my-3 p-3 bg-body rounded shadow-sm">
            <ng-container *ngIf="historic_list.length > 0">
              <ng-container *ngFor="let row of historic_list">
  
                <div class="cursor-pounter mt-3 my-3 p-3 bg-body rounded shadow">
                  <div class="text-dark pt-3 w-100 " >
                      <div class="pb-3 mb-0 small lh-sm  w-100">
                        <div class="d-flex justify-content-between">
                            <span> 
                              <span class="font-weight-bold">
                                  {{ row.type==="1"?'Diario': type==="2"?'Masivo': type==="3"?'Morosos': '' }} <br>
                                  {{ 'Lote: '+row.lote }}</span><br>
                                  {{ row.date | date: 'dd/MM/yyyy' }}<br>
                                  {{ getDescriptionStatus(row.status) }}
                              </span>
                            <br>
                            
        
                        </div>
        
                        
                      </div>
                    </div>
  
                  <!---->
                  <div class="pt-2 font-weight-bold small" *ngIf="row?.massiveProcessStatus.length>0">
                      Archivos:
                  </div>
                  <div class="small" *ngFor="let ele of row.massiveProcessStatus">
                      <div *ngIf="ele.status==='GENERATED'">
                        <a href="javascript:void()" (click)="downloadFile(ele.url, 'txt', 'domiciliacion_'+row.lote)" class="active-cursor" title="Descargar Archivo de CObro">Descargar</a> &nbsp; <span class="font-weight-bold">{{ele?.date | date: 'dd/MM/yyyy h:mm:ss a'}}</span> &nbsp; Archivo de pagos 
                      </div>
                      <div *ngIf="ele.status==='PROCESING'">
                        <a href="javascript:void()" (click)="downloadFile(ele.url, 'txt', 'domiciliacion_respuesta_banco_'+row.lote)" class="active-cursor" title="Descargar Archivo de Domiciliación">Descargar</a> &nbsp; <span class="font-weight-bold">{{ele?.date | date: 'dd/MM/yyyy h:mm:ss a'}}</span> &nbsp; Respuesta del Banco (Exitosos)<br>
                        <a href="javascript:void()" (click)="downloadFile(ele.url_error, 'txt', 'domiciliacion_respuesta_banco_errores_'+row.lote)" class="active-cursor" title="Descargar Archivo de Domiciliación">Descargar</a> &nbsp; <span class="font-weight-bold">{{ele?.date | date: 'dd/MM/yyyy h:mm:ss a'}}</span> &nbsp; Respuesta del Banco (Errores)

                      </div>
                      <div *ngIf="ele.status==='FINISHED'">
                        <a href="javascript:void()" (click)="downloadFile(ele.url_resume, 'xlsx', 'domiciliacion_resumen_general_'+row.lote)" class="active-cursor" title="Descargar Archivo de Pagos Masivos Corrida">Descargar</a> &nbsp;  <span class="font-weight-bold">{{ele?.date | date: 'dd/MM/yyyy h:mm:ss a'}}</span> &nbsp; <span *ngIf="ele?.url_resume">Resumen de Pagos Masivos </span>
  
                      </div>
                  </div>
  
                  <!-- <div class="border-bottom pt-4"></div> -->
                </div>
              </ng-container>
              
            </ng-container>
  
            <ng-container *ngIf="historic_list.length === 0">
              <div class="d-flex text-dark pt-3 ">
                <div class="pb-3 mb-0 small lh-sm cursor-pounter mt-3 my-3 p-3 bg-body rounded shadow w-100">
                  <div class="  text-center pt-3 pb-3">
                    SIN RESULTADOS
                  </div>
                 
                </div>
                
              </div>
              <br>
            </ng-container>
  
  
          </div>
        </div>
      </div>
    </div>
  </div>
  