import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { UserService } from 'src/app/services/user/user.service';
import { DirectDebitService } from 'src/app/services/direct-debit/direct-debit.service';
import { mobiscroll, MbscDatetimeOptions, MbscFormOptions } from '@mobiscroll/angular';
import { DatePipe } from '@angular/common';
import { saveAs } from 'file-saver';
import { CommonService } from 'src/app/services/common.service';
import { MassiveService } from 'src/app/services/massive.service';


mobiscroll.settings = {
  lang: 'es',
  theme: 'ios',
  themeVariant: 'light',
};
const now = new Date();
let week = [ new Date() ,new Date()];

@Component({
  selector: 'app-dashboard-massive-payments-history',
  templateUrl: './dashboard-massive-payments-history.component.html',
  styleUrls: ['./dashboard-massive-payments-history.component.css'],
  providers: [DatePipe]

})
export class DashboardMassivePaymentsHistoryComponent implements OnInit {

  range: Array < Date > = week;
  d1: any;
  d2: any;
  bank: any;
  type: any;
  loading = false;
  historic_list: any = [];

  types: any = [{
    "id": 1,
    "name": "Diario",
  },{
    "id": 2,
    "name": "Masivo",
  },{
    "id": 3,
    "name": "Morosos",
  }];

  statuses: any = [{
    "id": 'INITIAL',
    "name": "Sin comenzar",
  },{
    "id": "GENERATED",
    "name": "El archivo de carga másiva fue generado",
  },{
    "id": "DOWNLOADED",
    "name": "El archivo de carga másiva ha sido descargado",
  },{
    "id": "UPLOADED",
    "name": "El archivo ya fue cargado en el banco y se encuentra en espera de respuesta",
  },{
    "id": "PROCESING",
    "name": "Se recibió la respuesta del banco y se encuentra procesando",
  }
  ,{
    "id": "FINISHED",
    "name": "El proceso se finalizó y ya se realizó la conciliación",
  }];

  banks: any = [];

  dateSetting: MbscDatetimeOptions = {
    lang: 'es',
    theme: 'ios',
    themeVariant: 'light',
    max:now,
    responsive: {
      small: {
          controls: ['calendar'],
          display: 'center'
      },
      medium: {
          display: 'bubble',
      }
  }
  
  };

  form: FormGroup | any = this.formBuilder.group(
    {
      bank: [''],
      suscriptor: [''],
      account: ['']
    }
  );


  accounts: any = [];
  accounts_suscriptor: any = [];
  customer_suscriptor: any;
  
  constructor(    
    private formBuilder: FormBuilder,
    private userService: UserService,
    private datePipe: DatePipe,
    private directDebitService: DirectDebitService,
    private _user: UserService,
    private commonService: CommonService,
    private massiveService: MassiveService
  ) { }

  birthdaySet = () => {

  }

  

  async ngOnInit() {
    this.banks = await this.getBanks();
    this.banks = this.banks.res;
    this.setDefault();
    this.updateCurrentDirectDebit();
    this.getAccounts('SUSCRIPTOR');
  }

  setDefault = () => {
    if (this.banks.length >= 1) {
      this.form.get('bank').setValue(this.banks[0].code);
      this.form.get('bank').patchValue(this.banks[0].code);
    }
  };

  getBanks = async () => {
    return await this.userService.getBanksWithMassivePaymentsEnabled({});
  };

  getAccounts = async (from: any) => {
    let account = await this._user.getPersonPeople_type({type: from});
    this.accounts =  account?.resp?.rows;
  }

  changeBank() {
    this.getAccountsSuscriptor();
  }


  getAccountsSuscriptor = async () => {
       let accounts_suscriptor = await this.commonService.getPayment_method({
        code_person: this.form.getRawValue().suscriptor
       });
       console.log(this.bank);
       debugger;
       if(accounts_suscriptor?.resp){
          this.accounts_suscriptor = accounts_suscriptor?.resp.filter((elem:any)=>elem.bank.code.toString() === this.form.getRawValue().bank.toString());
          if(this.accounts_suscriptor.length>0) {
              this.form.get('account').setValue(this.accounts_suscriptor[0]._id);
              this.form.get('account').patchValue(this.accounts_suscriptor[0]._id);
          } else {
            this.form.get('account').setValue('');
            this.form.get('account').patchValue('');
          }
       } else {
          this.form.get('account').setValue('');
          this.form.get('account').patchValue('');
       }
       this.updateCurrentDirectDebit();
   }



  updateCurrentDirectDebit = async () => {
    this.d1 = this.datePipe.transform(this.range[0], 'MM/dd/yyyy');
    this.d2 = this.datePipe.transform(this.range[1], 'MM/dd/yyyy');
    this.bank = this.form.get('bank').value;
    this.customer_suscriptor = this.form.get('suscriptor').value;

    if(this.customer_suscriptor && this.bank) {
      this.getHistoric();
    }
  }

  getHistoric = async () => {
    let body = {
      bank: this.bank,
      type: "MASSIVE_PAYMENTS",
      customer_suscriptor: this.form.get('suscriptor').value,
      from: this.d1,
      to: this.d2
    };
    this.historic_list = await this.massiveService.historic(body);
    if(this.historic_list) {
      this.historic_list = this.historic_list.resp;
    }
  }

  getDescriptionStatus = (value: string) => {
    let response = this.statuses.find((row: any)=> {
      return row.id===value;
    });
    return response.name;
  }

  downloadFile = async (url:any, extension: string, name: string) => {
    let res = await this.directDebitService.downloadImage(url, extension);
    if(res) {
      saveAs(res, `${name}.${extension}`);
    }
  }

}
