import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import {NgbDropdownConfig} from '@ng-bootstrap/ng-bootstrap';
import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { SocketioService } from 'src/app/services/socket/socketio.service';
import { AuthService } from 'src/app/services/auth/auth.service';

@Component({
  selector: 'app-navbarauth',
  templateUrl: './navbarauth.component.html',
  styleUrls: ['./navbarauth.component.css'],
})
export class NavbarauthComponent implements OnInit {
    env = environment;
    isAuth:any;
    sessionUser:any;
    activeMenu:any;
    menuOption:any;


  p_orderObj:any = {
    'PAYMENT_REQUEST': 'Solicitud de Pago',
    'REVIEW_AND_APPROVAL_OF_PAYMENT_REQUEST': 'Revisión y Aprobación de la Solicitud de Pago',
    'PAYMENT_AUTHORIZATION': 'Autorización del Pago',
    'PAYMENT_EXECUTION': 'Ejecución del Pago',
    'RECONCILIATION_AND_AUDIT': 'Conciliación y auditoria',
    'AUDIT': 'Auditoría',
    'FILING_AND_DOCUMENTATION': 'Archivo y Documentación',
};

  constructor(  
    private router: Router,
    public config: NgbDropdownConfig,
    private sanitizer: DomSanitizer,
    public socketService: SocketioService,
    public _auth: AuthService
  ) {
    config.placement = 'top-left';
    config.autoClose = true;

    this._auth.auth$.subscribe((rep: any) => {
      this.isAuth = rep;
      this.sessionUser = rep ? this._auth.sessionUser : null;

      let is = this._auth.IsPermitid(['OWNER', 'ANALIST']);

      //nathalyaespisona@totalmundo.com
    });
  }

  logout() {
    //this._auth.logout();

    this.router.navigate([environment.logoutRoute], {});
  }

  ngOnInit() {
    this._auth.menuOption.subscribe((rep: any) => {
      this.menuOption = rep;
    });
    this._auth.auth$.subscribe((rep: any) => {
      this.isAuth = rep;
      if (rep) {
        if (this.sessionUser?.role != 'COMPUTER_ROLE') {
          this.activeMenu = true;
        }
      }
    });
  }

  roleMapping: { [key: string]: string } = {
    OWNER: 'Owner',
    ANALIST: 'Analista',
    EJECUTIVE: 'Promotor',
    SHOP: 'Caja',
    PAYMENT_REQUEST: 'Solicitud de Pago',
    REVIEW_AND_APPROVAL_OF_PAYMENT_REQUEST:
      'Revisión y Aprobación de la Solicitud de Pago',
    PAYMENT_AUTHORIZATION: 'Autorización del Pago',
    PAYMENT_EXECUTION: 'Ejecución del Pago',
    RECONCILIATION_AND_AUDIT: 'Conciliación y auditoria',
    AUDIT: 'Auditoría',
    FILING_AND_DOCUMENTATION: 'Archivo y Documentación',
  };

  getProfileName(role: string): string {
    return this.roleMapping[role] || role;
  }

  redirectHome() {
    this.router.navigate([environment.loginRoute], {});
  }

  sanitize(url: string) {
    //return url;
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

  setOptionMenu = (opt: any) => {
    this._auth.setmenuOption(opt);
  };
}


