<ng-template #content let-offcanvas>
    <div class="offcanvas-header">
      <div class="container">
        <div class="row">
          <div class="col-12 col-md-6 offset-md-3">
            <div class="row">
              <div class="col-10">
                <div class="text-capitalize h5 text-secondary mt-2">Bancos</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
  <div class="mt-md-3 p-3">
    <div class="row">
      <div class="col-12 col-md-8 offset-md-2">
        <h5 class="mb-1 text-secondary mb-4 text-center"> Procesamiento másivo <br /> Ordenes de pago </h5>
        <ng-container>
          <div class="col-12 text-end pt-2">
            <button class="btn btn-outline-primary" routerLink="/console/order/dashboard-payment-orders-groups-history"> Histórico </button>
          </div>
          <div class="col-12 text-end pt-2">
            <form autocomplete="nope" novalidate [formGroup]="form" class="text-start">
              <div class="row mt-2">
                <div class="form-group col-12 mt-4">
                  <div class="form-floating">
                    <select class="form-select" id="bank" formControlName="bank" aria-label="Floating label select example" (change)="updateCurrentProcess()">
                      <option value="">Selecione una opción</option>
                      <option *ngFor="let item of banks; let i = index" [value]="item?.code">
                        {{ item.name }}
                      </option>
                    </select>
                    <label for="origin_account">Entidad Bancaria</label>
                  </div>
                </div>
                <div class="form-group col-12 mt-4">
                  <div class="form-floating">
                    <select class="form-select" id="type" formControlName="type" aria-label="Floating label select example" (change)="updateCurrentProcess()">
                      <option *ngFor="let item of types; let i = index" [value]="item?.id">
                        {{ item.name }}
                      </option>
                    </select>
                    <label for="origin_account">Acción</label>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <br />
          <div class="card box-shadow pt-3" *ngIf="current_process">
            <div class="card-body">
              <div class="row">
                <div class="col-6">
                  <h5 class="card-title">Lote</h5>
                  <h6 class="card-subtitle mb-2 text-muted">{{ current_process?.lote }}</h6>
                  <!-- <h6 class="card-subtitle mb-2 pt-3 font-weight-bold" *ngIf="current_process.rate">Tasa: {{ current_process.rate }} Bs </h6> -->
                </div>
                <div class="col-6">
                  <div class="float-end">
                    <h5 class="card-title fs-amount text-end"></h5>
                    <h6 class="card-subtitle mb-2 font-weight-bold text-end"></h6>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-footer">
              <small class="text-muted">Última actualización: {{ current_process?.date  | date : "dd/MM/yyyy h:mm:ss a" }}</small>
            </div>
          </div>
          <div class="mt-4 list-group list-group-radio d-grid gap-2 border-0 w-auto" *ngIf="this.form.getRawValue().type && this.form.getRawValue().bank">
            <div *ngIf="current_process">
              <div class="card box-shadow" *ngIf="current_process.total_amount > 0">
                <div class="card-body">
                  <div class="row">
                    <div class="col-6">
                      <h5 class="card-title">Lote</h5>
                      <h6 class="card-subtitle mb-2 text-muted">
                        {{ current_process.lote }}
                      </h6>
                      <!-- <h6 class="card-subtitle mb-2 pt-3 font-weight-bold" *ngIf="current_process.rate">Tasa: {{ current_process.rate }} Bs </h6> -->
                    </div>
                    <div class="col-6">
                      <div class="float-end">
                        <h5 class="card-title fs-amount text-end">
                          {{ current_process.total_amount }} Bs
                        </h5>
                        <h6 class="card-subtitle mb-2 font-weight-bold text-end" *ngIf="current_process.rate"> Dolar BCV: {{ current_process.rate }} Bs </h6>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card-footer">
                  <small class="text-muted">Última actualización: {{ current_process.date | date : "dd/MM/yyyy h:mm:ss a"
                    }}</small>
                </div>
              </div>
            </div>
            <br *ngIf="current_process" />
            <!-- *ngFor="let item of section_transactions"  -->
            <div *ngFor="let section of sections; let i = index" class="position-relative box" style="border-radius: 10px;">
              <label class="list-group-item py-4" for="listGroupRadioGrid1">
                <ng-container *ngIf="!loading">
                  <!-- {{ profiles | json }}
                                  {{ profile | json }} -->
                  <!-- <div class="inner-box">1 -->
                  <div class="row">
                    <div class="col-md-1 col-2">
                      <div id="circle" class="completed circle-enabled" [class.completed]="
                          status_sections[section.status.trim()] !== 'PENDING'
                        ">
                        {{ i + 1 }}
                        <i class="fas fa-check verification-icon"></i>
                      </div>
                      <!-- {{ status_sections[section.status.trim()] | json }} -->
                    </div>
                    <div class="col-md-11 col-10">
                      <span class="pl-5 font-weight-bold" [innerHTML]="section.name"></span>
                      <div class="text-secondary small" *ngIf="status_sections[section.status] !== 'PENDING'">
                        {{ status_sections[section.status]?.date
                            | date : "dd/MM/yyyy h:mm:ss a"
                        }}
                      </div>
                      <div class="small" *ngIf="status_sections[section.status].user">
                        <i class="fa fa-user text-secondary" aria-hidden="true"></i>
                        {{ status_sections[section.status]?.user?.name +
                            " " +
                            status_sections[section.status]?.user?.lastName
                        }}
                      </div>
                      <span *ngIf="
                          section.status === 'INITIAL' &&
                          status_sections[section.status] === 'PENDING' &&
                          _auth.IsPermitid(['OWNER', 'RECONCILIATION_AND_AUDIT']) &&
                          !current_process
                        ">
                        <ng-container>
                          <!-- && profile['key']==='ADMIN'-->
                          <div class="w-100 pb-4">
                            <br />
                            <app-payment-orders-dnd (response)="getResponse($event)" (reset)="reset($event)" [config]="config_read" *ngIf="
                                status_sections['INITIAL'] === 'PENDING' &&
                                !current_process &&
                                config_read
                              "></app-payment-orders-dnd>
                            <span class="float-end" *ngIf="show_conciliacion_button">
                              <button class="btn btn-primary btn-sm" [class.disabled]="loading_section[section.status]" (click)="procesar()">
                                <i class="fas fa-circle-notch fa-spin" *ngIf="loading_section[section.status]"></i> Procesar </button>
                            </span>
                          </div>
                        </ng-container>
                      </span>


                      <span *ngIf="
                        section.status === 'INITIAL'  &&
                        _auth.IsPermitid(['OWNER', 'RECONCILIATION_AND_AUDIT']) &&
                        current_process
                      ">
                        <div class="pt-3 w-100" *ngIf="current_process">

                          <app-direct-debit-trace-document [setType]="
                            'MASSIVE_PROCESS_CONCILIATION_' +
                            current_process.trace
                          " (trace)="updateCurrentProcess()" (response)="setResponse($event, 'CONCILIATION')"></app-direct-debit-trace-document>


                          <div class="float-end pt-1" *ngIf="btn_restart_conciliation">
                            <button type="button" class="btn btn-danger btn-sm" (click)="restart_process_conciliation()">
                              <i class="fas fa-circle-notch fa-spin" *ngIf="loading_section[section.status]"></i> Reintentar </button>&nbsp;
                          </div>


                          <div class="w-100" *ngIf="status_sections['INITIAL'] !== 'PENDING'">
                            <div class="files-list">
                              <div class="single-file active-cursor" title="Descargar">
                                <img src="assets/public/images/ic-file.svg" width="45px" alt="file" />
                                <div class="info">
                                  <h4 class="name small">
                                    <a href="javascript:void()" (click)="
                                    downloadFile(
                                      status_sections[section.status]?.url,
                                      'xlsx',
                                      'domiciliacion_respuestabanco_' +
                                        current_process.lote
                                    )
                                  " class="active-cursor" title="Descargar Archivo de Domiciliación">Descargar Archivo Procesado</a>
                                  </h4>
                                  <p class="size">
                                    {{ "" }}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>

                        </div>
                      </span>


                      <span *ngIf="section.status === 'FINISHED'">
                        <div class="w-100" *ngIf="status_sections['FINISHED'] !== 'PENDING'">
                          <div class="files-list">
                            <div class="single-file active-cursor" title="Descargar">
                              <img src="assets/public/images/ic-file.svg" width="45px" alt="file" />
                              <div class="info">
                                <h4 class="name small">
                                  <a href="javascript:void()" (click)="
                                      downloadFile(
                                        status_sections[section.status]?.url_resume,
                                        'xlsx',
                                        'domiciliacion_resultado_' +
                                          current_process.lote
                                      )
                                    " class="active-cursor" title="Descargar Archivo de Domiciliación Corrida">Descargar Respuesta Conciliación</a>
                                </h4>
                                <p class="size">
                                  {{ "" }}
                                </p>
                              </div>
                            </div>
                            <div class="single-file active-cursor" title="Descargar" *ngIf="status_sections[section.status]?.url_failed">
                              <img src="assets/public/images/ic-file.svg" width="45px" alt="file" />
                              <div class="info">
                                <h4 class="name small">
                                  <a href="javascript:void()" (click)="
                                      downloadFile(
                                        status_sections[section.status]
                                          ?.url_failed,
                                        'xlsx',
                                        'domiciliacion_resultado_general_' +
                                          current_process.lote
                                      )
                                    " class="active-cursor" title="Descargar Archivo de Domiciliación Resumen">Descargar Respuesta Conciliación (General)</a>
                                </h4>
                                <p class="size">
                                  {{ "" }}
                                </p>
                              </div>
                            </div>
                          </div>
                          <span class="float-end">
                            <button class="btn btn-primary btn-sm" (click)="finalizar()"> Finalizar </button>
                          </span>
                        </div>
                      </span>
                    </div>
                  </div>
                </ng-container>
                <ng-container *ngIf="loading">
                  <div class="col-12 text-center">Cargando...</div>
                </ng-container>
              </label>
            </div>
          </div>
          <div class="mt-4 list-group list-group-radio d-grid gap-2 border-0 w-auto" *ngIf="!this.form.getRawValue().type || !this.form.getRawValue().bank">

            <div class="position-relative box">
              <label class="list-group-item py-4 text-center font-weight-bold" for="listGroupRadioGrid1">
                <h4>Seleccione el tipo y banco para iniciar el proceso </h4>
              </label>
            </div>

          </div>
        </ng-container>
        <ng-container *ngIf="loading">
          <div class="w-100 text-center mt-2">
            <img src="./assets/public/images/loading.gif  " alt=" " />
          </div>
        </ng-container>
      </div>
    </div>
  </div>
