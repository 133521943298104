import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DirectDebitService } from '../../../services/direct-debit/direct-debit.service';

@Component({
  selector: 'app-massive-dnd',
  templateUrl: './dnd.component.html',
  styleUrls: ['./dnd.component.css']
})
export class DndComponent implements OnInit {

  @Output() response = new EventEmitter<any>();
  @Output() reset = new EventEmitter<any>();
  // @Input() loading(_loading: boolean) {
  //   this._loading = _loading;
  // };

  @Input() set direct_debit(_direct_debit: any) {
    this._direct_debit = _direct_debit;
  };

  @Input() set config(config: any) {
    this.config_read = config;
  };

  @Input() set type(type: any) {
    this._type = type;
  };

  @Input() set text(_text: any) {
    this._text = _text;
  };

  _loading = false;
  _direct_debit: any;
  _type: any;
  _text: any = "Arrastre y suelte el archivo aquí";
  verified = false;
  config_read: any;
  error = false;


  constructor(
    private directDebitService: DirectDebitService,
  ) { }

  ngOnInit(): void {
    
  }

  @ViewChild("fileDropRef", { static: false }) fileDropEl: ElementRef | undefined;
  files: any[] = [];

  /**
   * on file drop handler
   */
  onFileDropped(files: any) {
   
    this.prepareFilesList(files);
  }

  /**
   * handle file from browsing
   */
  fileBrowseHandler($event: any) {
    debugger;
    let files = ($event.target as HTMLInputElement).files;
    this.prepareFilesList(files);
  }

  /**
   * Delete file from files list
   * @param index (File index)
   */
  deleteFile(index: number) {
    if (this.files[index].progress < 100) {
      console.log("Upload in progress.");
      return;
    }
    this.files.splice(index, 1);
    this.verified = false;
    this.reset.emit(true);
  }

  /**
   * Simulate the upload process
   */
  uploadFilesSimulator(index: number) {
   
      if (index === this.files.length) {
        return;
      } else {
        const progressInterval = setInterval(() => {
          if (this.files[index].progress === 100) {
            clearInterval(progressInterval);
            this.uploadFilesSimulator(index + 1);
          } else {
            this.files[index].progress += 5;
          }
        }, 200);
      }
   
  }

  /**
   * Convert Files list to normal array list
   * @param files (Files List)
   */
  prepareFilesList(files: any) {
    this.error = false;
    this.verified = false;
    this._loading = true;
    this.files = [];
    for (const item of files) {
      item.progress = 0;
      this.files.push(item);
    }
    if (this.fileDropEl && this.fileDropEl.nativeElement) {
      this.fileDropEl.nativeElement.value = "";
    }
    this.uploadFilesSimulator(0);
    this.readFileService();
  }

  readFileService = async () => {
    this.verified = false;
    this.error = false;
    try{
      let lote = this._direct_debit.manual_lote?this._direct_debit.manual_lote.padStart(10, '0').toString(): this._direct_debit.lote.padStart(10, '0').toString();
      let config = this.config_read.config;
      let response;
      if(this.config_read.custom_function) {
        response = await this.directDebitService.readDirectDebitCustomFile(config, this.files[0], this.config_read.custom_function);
      } else {
        response = await this.directDebitService.readDirectDebitFile(config, this.files[0]);
      }
      debugger;
      config = JSON.parse(this.config_read.config);
      if(config.type==='bancaamiga_massive_payment_response') {
        // si es el flujo de bancaamiga, hacemos las validaciones, tomamos el lote pero tambien validamos el tipo (errores, exitosos)
        const regex = /del lote (\d{1,4})/;
        const texto = response?.data[1][0];
        const resultado = regex.exec(texto);
        if (resultado && resultado[1]) {
          const numeroLote = resultado[1].padStart(10, '0').toString();
          if (!texto.includes(this._type) || !numeroLote?.toString().includes(lote)) {
            this._loading = false;
            this.error = true;
            this.response.emit(null);
          } else {
            this._loading = false;
            this.verified = true;
            this.response.emit({response, file: this.files[0]});
          }
        } else {
          this._loading = false;
          this.error = true;
          this.response.emit(null);
        }
      } else {
        let header = this.getHeader(response.data);
        this._loading = false;
        if(this._direct_debit.lote.padStart(10, '0').toString()===header['LOTE'].padStart(10, '0').toString()) { // aqui tendría que validar el lote
          this.verified = true;
          this.response.emit({response, file: this.files[0]});
        }else {
          this.error = true;
          this.response.emit(null);
        }
      }
      

      

      
      
    } catch(error) {
      console.log(error);
      this._loading = false;
      this.error = true;
      this.response.emit(null);
      console.log('entra por la via del error');
      console.log(false);
      
    }
  }

  getHeader = (obj: any) => {
    return obj.find((row: any) => row.TIPOREG==="01" );
  }

  /**
   * format bytes
   * @param bytes (File size in bytes)
   * @param decimals (Decimals point)
   */
  formatBytes(bytes: number, decimals = 2) {
    if (bytes === 0) {
      return "0 Bytes";
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }


}
