<!-- <pre>Buro {{ buro | json }}</pre> -->


<div class="" *ngIf="step == 1">
    <form autocomplete="nope" novalidate [formGroup]="form" (ngSubmit)="submit()" class="text-start">
        <div class="row mt-3">
            <div class="form-group  col-12">

                <div class="form-floating">
                    <select [ngClass]="{'error': form.controls.type_identity.invalid && form.controls.type_identity.dirty}" class="form-select" id="type_identity" formControlName="type_identity" aria-label="Floating label select example">
                        <option  value="">Selecione</option>
                        <option *ngFor="let item of type_identity"  [value]="item.code">{{item.name}}</option>
                    </select>

                    <label for="type_identity">Tipo de Documento</label>
                </div>

                <div class="form-control-feedback help-block" *ngIf="form.get('type_identity').errors">
                    <div *ngIf="form.get('type_identity').errors['required'] && !form.get('type_identity').pristine">
                        Campo requerido
                    </div>
                </div>

            </div>

            <div class="form-group  col-4 mt-3">

                <div class="form-floating">
                    <select [ngClass]="{'error': form.controls.code_identity.invalid && form.controls.code_identity.dirty}" class="form-select" id="code_identity" formControlName="code_identity" aria-label="Floating label select example">
                        <option  value="">Selecione</option>
                        <option *ngFor="let item of code_identity"  [value]="item.code">{{item.name}}</option>
                                 </select>



                    <label for="code_identity">Tipo</label>
                </div>

                <div class="form-control-feedback help-block" *ngIf="form.get('code_identity').errors">
                    <div *ngIf="form.get('code_identity').errors['required'] && !form.get('code_identity').pristine">
                        Campo requerido
                    </div>
                </div>
            </div>

            <div class="form-group  col-8  mt-3">

                <div class="form-floating ">
                    <input type="text" [ngClass]="{'error': form.controls.identity.invalid && form.controls.identity.dirty}" class="form-control text-capitalize" id="identity" formControlName="identity">
                    <label for="identity" class=" label font-weight-normal" style="word-wrap: break-word;">
                      <span >Documento </span>
                 </label>
                </div>

                <div class="form-control-feedback help-block" *ngIf="form.get('identity').errors">
                    <div *ngIf="form.get('identity').errors['required'] && !form.get('identity').pristine">
                        Campo requerido
                    </div>
                    <div *ngIf="form.get('identity').hasError('minlength')  && !form.get('identity').pristine">
                        Mínimo 6 caracteres
                    </div>

                    <div *ngIf="form.get('identity').hasError('maxlength')  && !form.get('identity').pristine">
                        Máximo 20 caracteres
                    </div>

                </div>
            </div>

            <br>
            <div class="form-group  col-12 mt-4 mb-2">
                <div class=" float-end">

                    <button type="submit" [disabled]="!form.valid || submit_disabled" class="btn btn-block btn-primary ">
                                    <span >Consultar</span>
                                    <span *ngIf="submit_disabled">
                                      <i class="fa  fa-spinner fa-spin ms-2"></i>
                                    </span>
                    </button>


                </div>

            </div>

        </div>
    </form>

    <div (click)="close.emit(true)" [routerLink]="['/console/person/profile/',person?.person?.code]" class="alert alert-primary bg-white mt-3" role="alert" *ngIf="person && result">

        {{person?.person?.name}} {{person?.person?.lastName}}

        <button class="btn btn-outline-secondary btn-sm float-end "> Ver perfil </button>
    </div>


</div>



<div class="" *ngIf="step == 3">

    <div class="d-flex mt-2">
        <div class="text-secondary ">
            Documento
        </div>
        <div class="fw-bold fz-14 ms-auto">
            <span class="pe-1">{{person?.type_identity}}.</span> {{person?.code_identity}}-{{person?.identity}} </div>
    </div>
    <div>


        <div class="d-flex mt-2" *ngIf="person_data || type != 'CLIENT'">
            <div class="text-secondary ">
                Nombre
            </div>
            <div class="fw-bold fz-14 ms-auto">
                <span class="pe-1">{{person_data?.person?.name}}</span>
            </div>
        </div>

        <div class="d-flex mt-2">
            <div class="text-secondary ">
                Apellido
            </div>
            <div class="fw-bold fz-14 ms-auto">
                <span class="pe-1">{{person_data?.person?.lastName}}</span>
            </div>
        </div>

    </div>



    <form *ngIf="!loading" autocomplete="nope" novalidate [formGroup]="form3" class="text-start">
        <div class="row mt-3" *ngIf="!person_data || type == 'CLIENT'">

            <div [ngClass]="{'d-none':show_data_buro}" class="form-group col-12 mt-3">

                <div class="form-floating ">
                    <input [disabled]="buro" type="text" [ngClass]="{'error': form3.controls.name.invalid && form3.controls.name.dirty}" class="form-control text-capitalize" id="name" formControlName="name" placeholder="name@example.com">
                    <label for="name" class=" label font-weight-normal" style="word-wrap: break-word;">
                    <span *ngIf="form.getRawValue()?.type_identity != 'RIF'" >Primer nombre</span>
                    <span *ngIf="form.getRawValue()?.type_identity == 'RIF'" >Nombre</span>

                <span class="text-danger ps-1">*</span>
                </label>
                </div>

                <div class="form-control-feedback help-block" *ngIf="form3.get('name').errors">
                    <div *ngIf="form3.get('name').errors['required'] && !form3.get('name').pristine">
                        Campo requerido
                    </div>
                </div>

            </div>

        <div *ngIf="form.getRawValue()?.type_identity != 'RIF'">

            <div [ngClass]="{'d-none':show_data_buro}" class="form-group col-12 mt-3">

                <div class="form-floating ">
                    <input type="text" [ngClass]="{'error': form3.controls.second_name.invalid && form3.controls.second_name.dirty}" class="form-control text-capitalize" id="second_name" formControlName="second_name" placeholder="second_name@example.com">
                    <label for="second_name" class=" label font-weight-normal" style="word-wrap: break-word;">
                <span >Segundo nombre</span>
            </label>
                </div>


            </div>

            <div [ngClass]="{'d-none': show_data_buro}" class="form-group col-12 mt-3">

                <div class="form-floating">
                    <input [disabled]="buro" type="text" [ngClass]="{'error': form3.controls.lastName.invalid && form3.controls.lastName.dirty}" class="form-control text-capitalize" id="lastName" formControlName="lastName" placeholder="name@example.com">
                    <label for="lastName" class=" label font-weight-normal" style="word-wrap: break-word;">
                    <span >Primer apellido</span>
                    <span class="text-danger ps-1">*</span>

                </label>
                </div>

                <div class="form-control-feedback help-block" *ngIf="form3.get('lastName').errors">
                    <div *ngIf="form3.get('lastName').errors['required'] && !form3.get('lastName').pristine">
                        Campo requerido
                    </div>
                </div>

            </div>


            <div [ngClass]="{'d-none': show_data_buro}" class="form-group col-12 mt-3">

                <div class="form-floating">
                    <input type="text" [ngClass]="{'error': form3.controls.second_lastName.invalid && form3.controls.second_lastName.dirty}" class="form-control text-capitalize" id="second_lastName" formControlName="second_lastName" placeholder="second_name@example.com">
                    <label for="second_lastName" class=" label font-weight-normal" style="word-wrap: break-word;">
                <span >Segundo apellido</span>
            </label>

           </div>
            </div>
        </div>


        </div>

        <div class="row">

            <div class="form-group col-12  mt-3">
                <div class="text-secondary text-end fz-12">
                    Eje: ejemplo@email.com
                </div>

                <div class="input-group mb-3">
                    <div class="form-floating ">
                        <input type="text" [ngClass]="{'error': form3.controls.email.invalid && form3.controls.email.dirty}" class="form-control" id="email" formControlName="email" placeholder="name@example.com">
                        <label for="email" class=" label font-weight-normal" style="word-wrap: break-word;">
                            <span >Email</span>
                        </label>
                    </div>
                </div>

                <div class="form-control-feedback help-block" *ngIf="form3.get('email').errors">
                    <div *ngIf="form3.get('email').errors['required'] && !form3.pristine">
                        Campo requerido
                    </div>
                </div>
                <div class="form-control-feedback help-block" *ngIf="form3.get('email').hasError('pattern')  && !form3.pristine">
                    <span>Email invalido</span>
                </div>
            </div>

            <div class="form-group col-12  mt-3">

                <div class="text-secondary text-end fz-12">
                    Eje: 4240000000
                </div>

                <div class="input-group mb-3" >
                    <div class="t58" *ngIf="form3.getRawValue()?.contact != ''">
                        +58

                    </div>

                    <div class="form-floating ">
                        <input type="text" [ngClass]="{'error': form3.controls.contact.invalid && form3.controls.contact.dirty,'t582':form3.getRawValue()?.contact != ''}" class="form-control " id="contact" formControlName="contact" placeholder="name@example.com">
                        <label for="contact" class=" label font-weight-normal" style="word-wrap: break-word;">
                            <span >Teléfono Celular</span>
                        </label>
                    </div>



                </div>

                <div class="form-control-feedback help-block" *ngIf="form3.get('contact').errors">
                    <div *ngIf="form3.get('contact').errors['required'] && !form3.pristine">
                        Campo requerido
                    </div>
                </div>
            </div>

        </div>
    </form>



    <br>
    <div *ngIf=" !loading" class=" col-12 mt-4 mb-2">
        <button (click)="sendPerson()" type="button" [disabled]="!form3.valid || submit_disabled" class="btn btn-block btn-primary ">
                        <span >Continuar</span>
                        <span *ngIf="submit_disabled">
                          <i class="fa  fa-spinner fa-spin ms-2"></i>
                        </span>
        </button>
    </div>
</div>

<div class="" *ngIf="step == 4">

    <app-validate-phone *ngIf="activeValidateTlf" [selected]="activeValidateTlf" [config]="dataValidateTlf" (close)="closeValidateTlf($event)" (setear)="setValidateTlf($event)">
    </app-validate-phone>

</div>
<!-- <pre>{{ person_data| json }}</pre>
<pre>{{ person | json }}</pre> -->
