<ng-template #content let-offcanvas>
    <div class="offcanvas-header">
        <div class="container ">
            <div class="row">
                <div class="col-12 col-md-6 offset-md-3 ">
                    <div class="row">
                        <div class="col-10">
                            <div class="text-capitalize h5 text-secondary mt-2 ">
                                Contacto
                            </div>
                        </div>
                        <div class="col-2 text-end pt-2">
                            <button type="button" class="btn-close" aria-label="Close" (click)="offcanvas.dismiss('Cross click')"></button>
                        </div>
                    </div>
                </div>

            </div>

        </div>
    </div>

    <div class="offcanvas-body">
        <div class=" container">

            <div class="row">
                <div class=" col-12 col-md-6 offset-md-3">

                    <app-search-client (close)="closeAddClient()"></app-search-client>
                </div>
            </div>
        </div>
    </div>

</ng-template>

<div class="container">

    <div class="mt-md-3 p-3">

        <div class="row ">
            <div class=" col-12 col-md-6 offset-md-3  p-0 ">


                <h5 class="mb-4 text-secondary mb-5 text-center">
                    <i class="  fas fa-users"></i>

                    <span *ngIf="!shopEjecutive && _auth.IsPermitid(['EJECUTIVE'])">
                         Confirma la tienda donde estas 
                    </span>
                    <span *ngIf="!(!shopEjecutive && _auth.IsPermitid(['EJECUTIVE']))">
                        Contactos
                    </span>
                </h5>

                <!-- <div class="mt-4 mb-5 text-center w-100">
                        <img class="img-style base_img" [src]="sanitize('../assets/public/images/banner.png')">

                    </div> -->





                <div class="mt-5 ">


                    <div *ngIf="!shopEjecutive && _auth.IsPermitid(['EJECUTIVE'])" class="d-flex justify-content-between mt-4">

                        <div class="input-group ">

                            <div class="form-floating">
                                <select [(ngModel)]="shop" class="form-select" id="gender" aria-label="Floating label select example">
                                    <option  value="">Selecione</option>
                                    <option *ngFor="let item of shops"  [value]="item.code">{{item.name}}</option>

                                </select>
                                <label for="gender">Tienda</label>
                            </div>
                            <button (click)="changeShop()" [disabled]=" submit_disabled_shop" class="btn btn-primary">
                                Guardar
                                <span *ngIf="submit_disabled_shop">
                                <i class="fa  fa-spinner fa-spin"></i>
                                </span>
                            </button>

                        </div>

                    </div>



                    <div *ngIf="!(!shopEjecutive && _auth.IsPermitid(['EJECUTIVE']))">



                        <div class="row">
                            <!-- <div class="col-12 mt-4">
                                <div class="card" *ngIf="_auth.IsPermitid(['OWNER','ANALIST','EJECUTIVE','PAYMENT_REQUEST', 'REVIEW_AND_APPROVAL_OF_PAYMENT_REQUEST', 'PAYMENT_AUTHORIZATION','AUTHORIZATION_PAYMENT_EXECUTION', 'PAYMENT_EXECUTION', 'RECONCILIATION_AND_AUDIT', 'AUDIT'])" (click)="openBottom(content,{})">
                                    <div class="card-body">
                                        <h5 class="card-title">Buscar

                                            <i class="float-end pt-2  fas fa-search"></i>

                                        </h5>
                                    </div>
                                </div>
                            </div>-->

                            <div class="col-12  mt-4" *ngIf="_auth.IsPermitid(['OWNER','ANALIST','EJECUTIVE'])">
                                <div class="card" [routerLink]="['/console/constacts']">
                                    <div class="card-body">
                                        <h5 class="card-title">Clientes
                                            <i class="float-end pt-2  fas fa-users"></i>

                                        </h5>

                                    </div>
                                </div>
                                
                            </div>

        
                            <ng-container *ngFor="let item of person_types"  >
                            
                            <div class="col-12  mt-4" *ngIf="_auth.IsPermitid(['OWNER','PAYMENT_REQUEST', 'REVIEW_AND_APPROVAL_OF_PAYMENT_REQUEST', 'PAYMENT_AUTHORIZATION','AUTHORIZATION_PAYMENT_EXECUTION', 'PAYMENT_EXECUTION', 'RECONCILIATION_AND_AUDIT', 'AUDIT'])">
                                <div class="card" [routerLink]="['/console/constacts/',item?.plural]">
                                    <div class="card-body">
                                        <h5 class="card-title">{{person_types_code_description_obj[item.code]?.plural}}
                                            <i class="float-end pt-2  fas fa-users"></i>

                                        </h5>

                                    </div>
                                </div>
                            </div>
                        </ng-container>


                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>