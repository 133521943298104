import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { DirectDebitService } from '../../../services/direct-debit/direct-debit.service';
import { MassiveService } from '../../../services/massive.service';

@Component({
  selector: 'app-payment-orders-dnd',
  templateUrl: './dnd.component.html',
  styleUrls: ['./dnd.component.css']
})
export class DndComponent implements OnInit {

  @Output() response = new EventEmitter<any>();
  @Output() reset = new EventEmitter<any>();
  // @Input() loading(_loading: boolean) {
  //   this._loading = _loading;
  // };

  @Input() set direct_debit(_direct_debit: any) {
    this._direct_debit = _direct_debit;
  };

  @Input() set config(config: any) {
    this.config_read = config;
  };

  _loading = false;
  _direct_debit: any;
  verified = false;
  config_read: any;
  error = false;


  constructor(
    private directDebitService: DirectDebitService,
    private massiveService: MassiveService
  ) { }

  ngOnInit(): void {
    
  }

  @ViewChild("fileDropRef", { static: false }) fileDropEl: ElementRef | undefined;
  files: any[] = [];

  /**
   * on file drop handler
   */
  onFileDropped(files: any) {
   
    this.prepareFilesList(files);
  }

  /**
   * handle file from browsing
   */
  fileBrowseHandler($event: any) {
    debugger;
    let files = ($event.target as HTMLInputElement).files;
    this.prepareFilesList(files);
  }

  /**
   * Delete file from files list
   * @param index (File index)
   */
  deleteFile(index: number) {
   
    this.files.splice(index, 1);
    this.verified = false;
    this.reset.emit(true);
  }

  
  /**
   * Convert Files list to normal array list
   * @param files (Files List)
   */
  prepareFilesList(files: any) {
    this.error = false;
    this.verified = false;
    this._loading = true;
    this.files = [];
    for (const item of files) {
      item.progress = 0;
      this.files.push(item);
    }
    if (this.fileDropEl && this.fileDropEl.nativeElement) {
      this.fileDropEl.nativeElement.value = "";
    }
    this.readFileService();
  }

  readFileService = async () => {
    this.verified = false;
    this.error = false;
    try{
      let config = this.config_read.config;
      //let response = await this.directDebitService.readDirectDebitFile(config, this.files[0]);
      let response = await this.massiveService.readCustomFile(config, this.files[0], this.config_read.custom_function);
      // se agrupa para validar la respuesta
      let respuesta_total = this.transformar(response.data);
      console.log(respuesta_total);
      let header = this.getHeader(response.data);
      this._loading = false;
      
      if(this.validateArrayObjects(respuesta_total)) { 
        this.verified = true;
        this.response.emit({response, file: this.files[0]});
      }else {
        this.error = true;
        this.response.emit(null);
      }
    } catch(error) {
      console.log(error);
      this._loading = false;
      this.error = true;
      this.response.emit(null);
      console.log('entra por la via del error');
      console.log(false);
    }
  }


  validateArrayObjects = (arr: any) => {
    const requiredKeys = ['accion', 'codigo_pago', 'observaciones'];
  
    return arr.every((obj: any) => {
      const hasAllKeys = requiredKeys.every(key => Object.keys(obj).includes(key));
      return hasAllKeys;
    });
  }

  getHeader = (obj: any) => {
    return obj[0];
  }

  transformar = (data: any) => {
    if (!Array.isArray(data) || data.length === 0) {
      throw new Error('Datos inválidos');
    }
    const headers = data[0];
    const rows = data.slice(1);
    return rows.map(row => {
      if (row.length !== headers.length) {
        throw new Error('Número de columnas inconsistente');
      }
  
      return headers.reduce((obj: any, header: any, index: any) => {
        obj[header.toLowerCase()] = row[index];
        return obj;
      }, {});
    });
    
  }

  /**
   * format bytes
   * @param bytes (File size in bytes)
   * @param decimals (Decimals point)
   */
  formatBytes(bytes: number, decimals = 2) {
    if (bytes === 0) {
      return "0 Bytes";
    }
    const k = 1024;
    const dm = decimals <= 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

}
