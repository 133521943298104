<div>
  <app-expandable-rows-table
    [data]="rows"
    [page]="page"
    [totalPages]="totalPages"
    [totalRows]="totalRows"
    [limit]="limit"
    [selectedRange]="selectedRange"
    [dateSetting]="dateSetting"
    [isLoading]="isLoading"
    [fromInfinite]="fromInfinite"
  ></app-expandable-rows-table>
  <ng-template #invoicetp let-offcanvas>
    <div class="offcanvas-header">
      <div class="container">
        <div class="row">
          <div class="col-12 col-md-10 offset-md-1">
            <div class="row">
              <div class="col-10">
                <div class="text-capitalize h5 text-secondary mt-2">
                  Invoice
                </div>
              </div>
              <div class="col-2 text-end pt-2">
                <button
                  type="button"
                  class="btn-close"
                  aria-label="Close"
                  (click)="offcanvas.dismiss('Cross click')"
                ></button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="offcanvas-body">
      <div class="container">
        <div class="row">
          <div class="col-12 col-md-10 offset-md-1">
            <div class="text-start">
              <app-contact-order-payment
                [code_invoice]="params_invoice.code_invoice"
                [code_installment]="params_invoice?.code_installment"
                [code_person]="params_invoice?.code_person"
                (resetStatus)="setResetStatus($event)"
              ></app-contact-order-payment>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</div>

<div class="container">
  <div class="row mt-5">
    <div class="col">
      <div class="flex items-center justify-center">
        <button
          type="button "
          class="text-white flex items-center px-4 py-2 bg-blue-400 border border-blue-300 rounded"
          (click)="getNex()"
          [disabled]="isLoading"
          [ngClass]="{
            'bg-gray-400 border border-gray-300': isLoading == true
          }"
        >
          <span *ngIf="!isLoading"> Siguientes + </span>
          <span *ngIf="isLoading">
            <i class="fas fa-spinner fa-spin" ></i> &nbsp;Cargando...
          </span>
        </button>
      </div>
    </div>
  </div>
</div>
