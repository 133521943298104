import { Component, EventEmitter, OnDestroy, OnInit, Output, TemplateRef} from '@angular/core';
import { NgbOffcanvas } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from 'src/app/services/user/user.service';
import { DomSanitizer } from '@angular/platform-browser';
import { SocketioService } from 'src/app/services/socket/socketio.service';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/services/auth/auth.service';
import * as alertifyjs from 'alertifyjs';


@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css'],
})
export class UsersComponent implements OnInit {
  shops: any = [];
  form: any;
  people: any = [];
  usersActives: any = {};
  socketServiceSub: any;
  submit_disabled: any;
  active_filter: any;
  reload_loading: any;
  rows_number = 0;
  rows_size = 10;

  rows_temp: any = [];
  role: any = 'USER_ROLE';
  supUse = false;
  view: any = 1;
  user_options: any;
  usQuote: any = false;
  submit_disabled_contact: any;
  password: any;
  submit_disabled_shop: any;
  submit_disabled_profile: any;
  profile: any = '';
  profiles: any = {};
  selectedProfiles: string[] = [];
  selectedRole: string = '';
  availableRoles: any[] = [];
  add_loading: boolean = false;
  delete_loading: boolean = false;
  showCheckboxes: boolean = false;
  shopSelection: { [key: string]: boolean } = {};
  showShopCheckboxes: boolean = false;

  shop: any = '';
  obj_shop: any = {};
  status_users: any = true;

  @Output() changeView = new EventEmitter<any>();

  page = 1;
  rows: any[] = [];
  people_total = 0;
  loading = false;
  reload_loading_pluss = false;
  limit = 50;
  totalFetched = 0;

  search_form: any = '';
  filter_base: any = {};

  p_order = [
    {
      code: 'PAYMENT_REQUEST',
      name: 'Solicitud de Pago',
    },
    {
      code: 'REVIEW_AND_APPROVAL_OF_PAYMENT_REQUEST',
      name: 'Revisión',
    },
    {
      code: 'PAYMENT_AUTHORIZATION',
      name: 'Autorización del Pago',
    },
    {
      code: 'AUTHORIZATION_PAYMENT_EXECUTION',
      name: 'Aprobación en banco'
    },
    {
      code: 'PAYMENT_EXECUTION',
      name: 'Ejecución del Pago',
    },
    {
      code: 'RECONCILIATION_AND_AUDIT',
      name: 'Conciliación y auditoria',
    },
    {
      code: 'AUDIT',
      name: 'Auditoría',
    },
    {
      code: 'FILING_AND_DOCUMENTATION',
      name: 'Archivo y Documentación',
    },
  ];

  

  p_orderObj:any = {
    'PAYMENT_REQUEST': 'Solicitud de Pago',
    'REVIEW_AND_APPROVAL_OF_PAYMENT_REQUEST': 'Revisión',
    'PAYMENT_AUTHORIZATION': 'Autorización del Pago',
    'AUTHORIZATION_PAYMENT_EXECUTION': 'Aprobación en banco',
    'PAYMENT_EXECUTION': 'Ejecución del Pago',
    'RECONCILIATION_AND_AUDIT': 'Conciliación y auditoria',
    'AUDIT': 'Auditoría',
    'FILING_AND_DOCUMENTATION': 'Archivo y Documentación',
};


  constructor(
    private offcanvasService: NgbOffcanvas,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private _user: UserService,
    private sanitizer: DomSanitizer,
    public socketService: SocketioService,
    public _auth: AuthService
  ) {}
  ngOnDestroy(): void {
    this.socketServiceSub.unsubscribe();
  }

  ngOnInit(): void {
    this.profiles = this._auth.getProfiles();

    this.getUsers();
    this.getshops();

    this.socketServiceSub = this.socketService.usersActives.subscribe(
      (resp: any) => {
        // this.usersActives = resp.filter((item:any) => item.uid != this.uid);

        this.usersActives = {};

        resp?.map((item: any) => {
          if (item?.role?.role == 'USER_ROLE') {
            this.usersActives[item?.person?.code] = true;
          }

          return item;
        });

        this.usersActives;
      }
    );
  }

  roleMapping: { [key: string]: string } = {
    OWNER: 'Owner',
    ANALIST: 'Analista',
    EJECUTIVE: 'Promotor',
    SHOP: 'Caja',
    PAYMENT_REQUEST: 'Solicitud de Pago',
    AUTHORIZATION_PAYMENT_EXECUTION: 'Aprobación en banco',
    REVIEW_AND_APPROVAL_OF_PAYMENT_REQUEST:
      'Revisión ',
    PAYMENT_AUTHORIZATION: 'Autorización del Pago',
    PAYMENT_EXECUTION: 'Ejecución del Pago',
    RECONCILIATION_AND_AUDIT: 'Conciliación y auditoria',
    AUDIT: 'Auditoría',
    FILING_AND_DOCUMENTATION: 'Archivo y Documentación',
  };

  openBottom(content: TemplateRef<any>, data: any = {}) {
    this.user_options = data;
    let user = this.user_options?.user;

    this.usQuote = user?.status;
    this.shop = user?.shop || '';

    let profile = this.getProfile(user);
    this.profile = profile ? profile?.keys : '';

    this.selectedProfiles = (user?.profile || []).map(
      (role: string) => this.roleMapping[role] || role
    );

    console.log(this.selectedProfiles);

    this.loadAvailableRoles(user);

    this.offcanvasService.open(content, {
      position: 'bottom',
      keyboard: false,
    });
  }

  loadAvailableRoles(user: any) {
    this.availableRoles = Object.keys(this.roleMapping)
      .filter(
        (roleKey) => !this.selectedProfiles.includes(this.roleMapping[roleKey])
      )
      .map((roleKey) => ({
        key: roleKey,
        value: this.roleMapping[roleKey],
      }));
  }

  closeAddClient() {
    this.getUsers();
    this.offcanvasService.dismiss('');
  }

  onRoleChange() {
    this.getUsers(true, true);
  }

  onShopChange() {
    this.getUsers(true, true);
  }

  roleSelection: { [key: string]: boolean } = {
    OWNER: false,
    ANALIST: false,
    EJECUTIVE: false,
    SHOP: false,
    AUDIT: false,
  };

  getUsers = async (loading = true, reload = false) => {
    this.loading = loading;

    if (reload) {
      this.page = 1;
      this.rows = [];
      this.totalFetched = 0;
    }

    let filter: any = {
      page: this.page,
      limit: this.limit,
      status: this.status_users,
    };

    if (this.search_form?.trim() !== '') {
      let search_filter = { f_all: this.search_form?.trim() };
      filter = { ...filter, ...search_filter };
    }

    const roleSelectionKeys = Object.keys(this.roleSelection).filter(
      (roleKey) => this.roleSelection[roleKey]
    );
    if (roleSelectionKeys.length > 0) {
      filter = { ...filter, roles: roleSelectionKeys.join(' ') };
    }

    const shopSelectionKeys = Object.keys(this.shopSelection).filter(
      (shopKey) => this.shopSelection[shopKey]
    );
    if (shopSelectionKeys.length > 0) {
      filter = { ...filter, shops: shopSelectionKeys.join(' ') };
    }

    try {
      const resp = await this._user.getUsers(filter);
      let result = resp.resp;
      this.people_total = result.total;

      let newRows = this.rows.concat(
        result.rows.filter(
          (row: any) =>
            !this.rows.some(
              (person: any) => person.person._id === row.person._id
            )
        )
      );
      newRows.sort((a: any, b: any) =>
        a.person.name.localeCompare(b.person.name)
      );

      this.rows = newRows;
      this.totalFetched = this.rows.length;
      this.page += 1;
      this.loading = false;
    } catch (error) {
      console.error('Error fetching users:', error);
      this.loading = false;
    }
  };

  toggleCheckboxes() {
    this.showCheckboxes = !this.showCheckboxes;
  }

  toggleShopCheckboxes() {
    this.showShopCheckboxes = !this.showShopCheckboxes;
  }

  nextRows = async () => {
    this.reload_loading_pluss = true;

    try {
      await this.getUsers(false, false);
    } catch (error) {
      console.error('Error fetching next rows:', error);
    } finally {
      this.reload_loading_pluss = false;
    }
  };

  hasMoreResults = () => {
    return this.totalFetched < this.people_total;
  };

  getIdentity(item: any) {
    if (!(item?.identitys?.length > 0)) return '';

    return `${item?.identitys[0]?.type_identity?.name} ${item?.identitys[0]?.code_identity?.name}-${item?.identitys[0]?.identity}`;
  }

  sanitize(url: string) {
    //return url;
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }
  onScrollDown() {
    if (!this.active_filter) {
      // this.nextRows();

      this.page = this.page + 1;

      this.getUsers();
    }

    console.log('scrolled down!!');
  }

  onScrollUp() {
    console.log('scrolled up!!');
  }

  onScroll() {
    console.log('scrolled!!');
  }

  offcanvasServiceClose() {
    this.view = 1;
    this.offcanvasService.dismiss();
  }

  setChangView(view: any) {
    this.view = view;
    //this.getSection_transaction_by_person();
  }

  toogleStatus = async (st = this.usQuote) => {
    let status = st ? true : false;
    try {
      let query = {
        code_user: this.user_options.user.code,
        status,
      };

      await this._user.status_user(query);
      alertifyjs.success('Cambio de estatus exitoso');

      this.getUsers(false, true);
    } catch (error) {
      this.usQuote = !this.usQuote;
      alertifyjs.error('Error al cambiar estatus');
      console.log(error);
    }
  };

  changeShop = async () => {
    try {
      let query = {
        code_user: this.user_options.user.code,
        code_shop: this.shop,
      };

      await this._user.shop_user(query);
      alertifyjs.success('Cambio de tienda exitoso');

      this.getUsers(false, true);
    } catch (error) {
      this.usQuote = !this.usQuote;
      alertifyjs.error('Error al cambiar tienda');
      console.log(error);
    }
  };

  changePass = async () => {
    try {
      let query = {
        code_user: this.user_options.user.code,
        password: this.password,
      };

      await this._user.pass_user(query);
      alertifyjs.success('Cambio clave exitoso');
      this.password = '';
      this.getUsers(false, true);
    } catch (error) {
      this.usQuote = !this.usQuote;
      alertifyjs.error('Error al cambiar clave');
      console.log(error);
    }
  };

  getshops = async () => {
    try {
      const resp = await this.getshopsServer();
      this.shops = resp?.res;
      let obj_shop: any = {};
      this.shops?.map((item: any) => {
        obj_shop[item.code] = item;
      });

      this.obj_shop = obj_shop;
    } catch (error) {
      console.error(error);
    }
  };

  getshopsServer = async () => {
    try {
      return this._user.getshops({});
    } catch (error) {
      console.error(error);
    }
  };

  getProfile(user: any) {
    let profiles = user?.profile;
    let profileArray: { key: string; value: any }[] = [];

    if (profiles && profiles.length > 0) {
      profiles.forEach((profileKey: string) => {
        let value = this.roleMapping[profileKey] || this.p_orderObj[profileKey] || profileKey;
        if (value) {
          profileArray.push({ key: profileKey, value });
        } else {
          console.log(`Profile key ${profileKey} has no corresponding value.`);
        }
      });
    }
    return profileArray;
  }

  async addRoleAndSave() {
    if (
      this.selectedRole &&
      !this.selectedProfiles.includes(
        this.roleMapping[this.selectedRole] || this.selectedRole
      )
    ) {
      this.selectedProfiles.push(
        this.roleMapping[this.selectedRole] || this.selectedRole
      );

      this.add_loading = true;

      const saved = await this.saveRoleChanges('add', this.selectedRole);

      if (saved) {
        this.selectedRole = '';
      }

      this.add_loading = false;
    }
  }

  async saveRoleChanges(
    action: 'add' | 'remove',
    role: string
  ): Promise<boolean> {
    try {
      this.submit_disabled_profile = true;
      let query = {
        code_user: this.user_options.user.code,
        action: action,
        role: role,
      };

      await this._user.profile_user(query);
      if (action === 'add') {
        alertifyjs.success('Perfil agregado!');
      } else if (action === 'remove') {
        alertifyjs.success('Perfil eliminado!');
      }
      this.getUsers(false, true);
      return true;
    } catch (error) {
      if (action === 'add') {
        alertifyjs.error('Error agregando el perfil');
      } else if (action === 'remove') {
        alertifyjs.error('Error eliminando el perfil');
      }
      console.error(error);
      return false;
    } finally {
      this.submit_disabled_profile = false;
    }
  }

  async removeRole(role: string) {
    const internalRole =
      Object.keys(this.roleMapping).find(
        (key) => this.roleMapping[key] === role
      ) || role;

    this.selectedProfiles = this.selectedProfiles.filter(
      (item) => item !== role
    );

    this.delete_loading = true;

    await this.saveRoleChanges('remove', internalRole);

    this.delete_loading = false;
  }
}
