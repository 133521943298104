<!-- <pre> {{ sections_status | json }}</pre> -->

<div class="row mb-5">
    <div class="col-12 ">

        <div class="card">
            <!-- <img src="..." class="card-img-top" alt="..."> -->
            <div class="card-body">
                <h5 class="card-title">Datos basicos
                    <span *ngIf="sections_status['PRE_REGISTER'] ==  'COMPLETE' || sections_status['BASE_CLIENT'] ==  'COMPLETE'" class="float-end fas fa-check text-success ps-1 small"></span>

                </h5>
                <p class="card-text"> <span class="text-capitalize fw-bold">{{person?.person?.name}} {{person?.person?.lastName}}</span> <br> <span *ngIf="type_identity !== 'RIF' && clientType !== 'PAYROLL'">{{person?.person?.gender == 'M'?'Masculíno':'Femenino'}},</span> {{getIdentity(person)}} <br *ngIf="clientType !== 'PAYROLL' && clientType !== 'PROVIDER' && clientType !== 'SUSCRIPTOR'"> <span *ngIf="clientType !== 'PAYROLL' && clientType !== 'PROVIDER' && clientType !== 'SUSCRIPTOR'">Fecha de nacimiento {{person?.person?.birth_date | date: 'dd/MM/yyyy'}}</span><br>
                    {{clientType === 'CLIENT' ? getContact(person?.contacts,"PHONE_CELL") : getContact(person?.contacts, "PHONE_CELL_" + clientType) }}  <br>

                    {{clientType === 'CLIENT' ? getContact(person?.contacts,"EMAIL") : getContact(person?.contacts, "EMAIL_" + clientType) }} <br>
                </p>

                 <!-- <pre>{{clientType}}</pre>
                 <pre>{{type_identity}}</pre> -->
                <!-- <div class="w-100 text-end mt-4">
                    <a href="#" class="btn btn-primary ">Editar</a>
                </div> -->

                <div class="w-100 text-end mt-4">
                    <a class="btn btn-primary " (click)="setChange(7)">Editar</a>
                </div>

            </div>
        </div>
    </div>
       <!-- <h1>{{clientType}}</h1> -->
    <!-- <pre>{{person.contacts[2].contact | json}}</pre> -->

    <div class="col-12 mt-4">
        <div class="card">
            <div class="card-body">
                <h5 class="card-title">Medios de pago
                    <span *ngIf="sections_status['PAYMENT_METHOD'] ==  'COMPLETE'" class="float-end  fas fa-check text-success ps-1 small"></span>

                </h5>
                <div class="card-text small">
                    <div *ngIf="!loadPerson_Address">

                        <div class="mt-2" *ngFor="let item of accounts; let i=index">
                            <div *ngIf="i > 1">
                                <hr>
                            </div>

                            <p class="card-text ">
                                <span class="card-title fw-bold ">{{item?.bank?.name}}</span>, {{ item?.account_type==="00"?"Corriente": "Ahorro" }}  ({{item?.currency?.name}}), {{item?.account_number}}, <span class="text-success">{{item?.terms_and_condition?'Domiciliada':''}}</span>
                            </p>
                        </div>


                    </div>


                    <ng-container *ngIf="loadPerson_Address">
                        <div class="w-100 text-center mt-2">
                            <img src="./assets/public/images/loading.gif  " alt=" ">
                        </div>
                    </ng-container>

                </div>
                <div class="w-100 text-end mt-4">
                    <a class="btn btn-primary " (click)="setChange(5)">Editar</a>
                </div>
            </div>

        </div>
    </div>

    <div class="col-12 mt-4" *ngIf="isClient">
        <div class="card">
            <div class="card-body">
                <h5 class="card-title">Direcciones
                    <span *ngIf="sections_status['ADDRESS'] ==  'COMPLETE'" class="float-end  fas fa-check text-success ps-1 small"></span>

                </h5>
                <div class="card-text small">

                    <div *ngIf="!loadPerson_Address">


                        <span *ngIf="sections_status['ADDRESS'] ==  'COMPLETE' && personAddress?.length < 1" class="float-end  fas fa-check text-success ps-1 small"></span>

                        <div class="mt-2" *ngFor="let item of personAddress; let i=index">
                            <div *ngIf="i > 1">
                                <hr>
                            </div>
                            <b>{{item?.address_type?.name}}</b>,{{item?.address?.country?.description}}, {{item?.address?.state?.name}}, {{item?.address?.city?.name}}, {{item?.address?.description}}
                        </div>


                    </div>


                    <ng-container *ngIf="loadPerson_Address">
                        <div class="w-100 text-center mt-2">
                            <img src="./assets/public/images/loading.gif  " alt=" ">
                        </div>
                    </ng-container>

                </div>
                <div class="w-100 text-end mt-4">
                    <a class="btn btn-primary " (click)="setChange(2)">Editar</a>
                </div>
            </div>
        </div>
    </div>

    <div class="col-12 mt-4" *ngIf="isClient">
        <div class="card">
            <div class="card-body">
                <h5 class="card-title">Condición Laboral
                    <span *ngIf="sections_status['WORKING_CONDITION'] ==  'COMPLETE'" class=" float-end  fas fa-check text-success ps-1 small"></span>

                </h5>
                <p *ngIf="sections_status['WORKING_CONDITION'] !=  'COMPLETE'" class="text-secondary small"> Pendiente</p>

                <p class="card-text"><span *ngIf="customer?.working_condition != 'Otro'">{{customer?.working_condition}}</span>
                    <span *ngIf="customer?.working_condition == 'Otro'">{{customer?.working_condition_descripion}}</span> </p>
                <div *ngIf="!estatus_approve"  class="w-100 text-end mt-4">
                    <a class="btn btn-primary " (click)="setChange(3)">Editar</a>
                </div>
            </div>
        </div>
    </div>

    <div class="col-12 mt-4" *ngIf="isClient">
        <div class="card">
            <div class="card-body">
                <h5 class="card-title">Ingreso Promedio
                    <span *ngIf="sections_status['INCOME'] ==  'COMPLETE'" class="float-end  fas fa-check text-success ps-1 small"></span>
                </h5>
                <p class="card-text"> {{customer?.amount}} USD</p>
                <p *ngIf="sections_status['INCOME'] !=  'COMPLETE'" class="text-secondary small"> Pendiente</p>
                <div *ngIf="!estatus_approve" class="w-100 text-end mt-4">
                    <a class="btn btn-primary " (click)="setChange(4)">Editar</a>
                </div>
            </div>
        </div>
    </div>
</div>
